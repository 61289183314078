<template lang="pug">
//- article-text
.article-text-box
  .article-text-title 文章列表
  .canslide
    .item-box
      .article-text-item.cursor-pointer(
        v-for="(para, index) in article.article_paras",
        v-bind:id="'content' + index",
        v-bind:class="[para.click_statu ? 'bg-white' : 'bg-gray']",
        @click="jumpContent(index)"
      )
        .text-num(
          v-bind:class="$root.size_show_status === 1 ? 'text-content-base' : [$root.size_show_status === 2 ? 'text-content-md' : 'text-content-sm']"
        ) {{ index + 1 }}.
        .article-content-all
          .item-img-box(v-if="para.image_id")
            img.item-img(:src="para.image_url")
          .text-content(
            v-if="($root.lan_show === 1 || $root.lan_show === 2) && para.content.content",
            v-bind:class="$root.size_show_status === 1 ? 'text-content-base' : [$root.size_show_status === 2 ? 'text-content-md' : 'text-content-sm']",
            v-html="para.content.content.replace(/\\r?\\n/g, '<br>')"
          )
          .text-ch-content(
            v-if="($root.lan_show === 1 || $root.lan_show === 3) && para.content_zh.content",
            v-bind:class="$root.size_show_status === 1 ? 'text-ch-content-base' : [$root.size_show_status === 2 ? 'text-ch-content-md' : 'text-ch-content-sm']",
            v-html="para.content_zh.content.replace(/\\r?\\n/g, '<br>')"
          )
        .clearfix
</template>
<script>
import $ from 'jquery'
export default {
  props: ['article'],
  name: 'article-text',
  components: {

  },
  data () {
    return {
    };
  },
  computed: {

  },
  mounted () {

  },
  created () {

  },
  methods: {
    //- 點擊跳至段落
    jumpContent (_index, _needSetPlay = true) {
      _index = (_index < 0) ? 0 : _index
      // console.log('--> 跳躍到指定對象: ', _index)

      var vm = this
      this.article.article_paras.forEach(function (_item, _rowIndex) {
        vm.article.article_paras[_rowIndex].click_statu = false
      })
      this.article.article_paras[_index].click_statu = true

      var target_top = $('#content' + _index).position().top;
      console.log('該段落距上方為：' + target_top)

      $('.canslide').animate({ scrollTop: target_top }, 300);

      // 通報更換目前選擇段落
      console.log('--> 通報更換目前選擇段落:', _index)
      this.$emit('changePara', _index)

      if (_needSetPlay) {
        // 朗讀模式
        if (!this.$root.article_switch) {
          this.$emit('playTargetTag', this.article.article_paras[_index].read_aloud_at)
        }
        // 解說模式
        else {
          this.$emit('playTargetTag', this.article.article_paras[_index].commentary_at)
        }
      }

      setTimeout(() => { this.$forceUpdate() }, 150)
    },
  }
}
</script>
