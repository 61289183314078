<template lang="pug">
//- video-screen
.video-screen(v-if="article")
  .video-screen-body
    video-player.video-player-box(
      v-if="article.type > 0 && currentMedia.url",
      :options="playerOptions",
      customEventName="customstatechangedeventname",
      :webkit-playsinline="true",
      :playsinline="true",
      :x5-playsinline="true",
      @play="onPlayerPlay",
      @pause="onPlayerPause",
      @ended="onPlayerEnded",
      @ready="playerReadied"
    )
  .video-mask(v-if="article.image_id && article.type < 2")
    img(:src="article.image_url")
  .video-mask(v-if="!$root.play_status && article.type > 0")
    .video-mask-playbox.cursor-pointer.row.align-items-center(
      @click="askPlayVideo()"
    )
      img.play-img.float-left(src="@/assets/image/play@3x.png")
      .play-text.float-left
        span(v-if="article.type === 1") 播放聲音
        span(v-if="article.type === 2") 播放影片
</template>
<script>
export default {
  name: 'video-screen',
  props: [
    'article',
    'currentMedia'
  ],
  components: {

  },
  watch: {
    '$root.play_speed_status' (_speed) {
      if (_speed) {
        switch (_speed) {
          case 1:
            this.videoPlayer.playbackRate(1.0);
            break;
          case 2:
            this.videoPlayer.playbackRate(1.5);
            break;
          case 3:
            this.videoPlayer.playbackRate(0.75);
            break;
        }
      }
    },
    currentMedia () {
      this.changeMedia()
    }
  },
  data () {
    return {
      move_persent: 0,
      checkHideMethod: false,
      play_video_status: false,
      play_video_persent: 0,
      video_play_show: false,
      video_play_anim: false,
      videoPlayer: false,
      playerOptions: {
        // 不給予控制功能
        controls: false,
        // 靜音
        muted: false,
        // 全版
        fill: true,
        // 語言
        language: 'zh-tw',
        // 看起來是可以加速的比例
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        // 影片檔案規格
        sources: []
      },
      videoType: false,
      hideAt: 0
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    askPlayVideo () {
      this.$root.play_status = true
    },
    // 當音視頻被啟動
    onPlayerPlay (player) {
      this.$emit('playStatus', true)
      console.log('[' + this.article.id + ']player play!', player)
    },
    // 當音視頻被暫停
    onPlayerPause (player) {
      this.$emit('playStatus', false)
      console.log('[' + this.article.id + ']player pause!', player)
    },
    // 當音視頻已結束
    onPlayerEnded (player) {
      this.$root.play_status = false
      console.log('[' + this.article.id + ']player ended!', player)
    },
    // player is ready
    playerReadied (player) {
      if (!this.article) {
        return
      }

      this.videoPlayer = player
      console.log('[' + this.article.id + ']the player is readied: ', player)

      this.changeMedia()
    },
    // 更換播放器影音
    changeMedia () {
      // 設置音視頻檔案
      if (this.currentMedia) {
        console.log('[' + this.article.id + ']---> 指定使用檔案: ', this.currentMedia)
        let mediaType = this.$root.getAttachmentMediaType(this.currentMedia.ext)
        this.videoPlayer.src({ type: mediaType, src: this.currentMedia.url })
      }

      // 按照類別處理畫面
      this.videoType = this.videoPlayer.currentType()
      console.log('[' + this.article.id + ']---> 目前播放的檔案類型: ', this.videoType)

      // 自動起始播放
      if (this.autoplay) {
        setTimeout(() => { this.startVideo() }, 300)
      }

    },
    // 啟動音視頻
    startVideo () {
      console.log('[' + this.article.id + ']--> start video..')
      this.videoPlayer.play()
      // this.$root.play_status = true
      this.$emit('playStatus', true)
    },
    // 暫停音視頻
    stopVideo () {
      console.log('[' + this.article.id + ']--> stop video..')
      this.videoPlayer.pause()
      // this.$root.play_status = false
      console.log('[' + this.article.id + ']--> 音視頻資訊: ', this.videoPlayer.toJSON())
      console.log('[' + this.article.id + ']--> 目前時間: ', this.videoPlayer.currentTime())
      this.$emit('playStatus', false)
    },
    // 返回當前時間
    getNowTime () {
      if (!this.videoPlayer) {
        return;
      }

      // 回傳目前時間
      return this.videoPlayer.currentTime()
    },
    // 獲取當前時間
    getCurrentTime () {
      if (!this.videoPlayer) {
        return;
      }

      // 請求暫停播放
      this.stopVideo()
      // 回傳目前時間
      let currentTime = this.videoPlayer.currentTime()
      this.$emit('tagTarget', {
        type: this.type,
        currentTime: currentTime
      })
    },
    // 自指定時間開始播放
    playTargetTime (_data) {
      if (!this.videoPlayer) {
        return;
      }

      console.log('[' + this.article.id + ']--> 自指定時間開始播放: ', _data)
      this.videoPlayer.currentTime(_data.setTime)
      this.videoPlayer.play()
      this.$root.play_status = true
    },
    // 啟動全螢幕
    enterFullScreen () {
      this.videoPlayer.requestFullscreen()
    },
  }
}
</script>

<style lang="sass" scoped>
.video-screen
  .video-screen-body
    width: 100%
    height: 100%
    .video-player-box
      width: 100%
      height: 100%
</style>