<template lang="pug">
//- 使用條款
.rule-view.page-index
  homeHeader
  .page-content
    .container-fluid-xs
      .rule-tab.text-align-left.row
        a.cursor-pointe.tab-btn.text-align-center.cant-select.cant-select(
          @click="changeChoice(1)",
          v-bind:class="$root.rule_status === 1 ? 'btn-active' : 'btn-noactive'"
        ) 隱私政策
        a.cursor-pointe.tab-btn.text-align-center.cant-select.cant-select(
          @click="changeChoice(2)",
          v-bind:class="$root.rule_status === 2 ? 'btn-active' : 'btn-noactive'"
        ) 使用條款
        a.cursor-pointe.tab-btn.text-align-center.cant-select.cant-select(
          @click="changeChoice(3)",
          v-bind:class="$root.rule_status === 3 ? 'btn-active' : 'btn-noactive'"
        ) 消費協議
      .rule-box
        //- 隱私政策
        PrivacyRule(v-if="$root.rule_status === 1")
        //- 使用條款
        UseRule(v-if="$root.rule_status === 2")
        //- 消費協議
        BuyRule(v-if="$root.rule_status === 3")

  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import UseRule from '@/components/rule/use-rule'
import PrivacyRule from '@/components/rule/privacy-rule'
import BuyRule from '@/components/rule/buy-rule'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    UseRule,
    PrivacyRule,
    BuyRule

  },
  data () {
    return {
    };
  },
  mounted () {
  },
  created () {
    this.$root.closeLoading()
  },
  computed: {
  },
  methods: {
    changeChoice (_tab) {
      this.$root.rule_status = _tab
    }
  }
}
</script>
<style lang="sass">
</style>
