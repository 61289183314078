<template lang="pug">
//- 首頁
.profile-view.page-index
  homeHeader
  .page-content
    .container-fluid-xs
      .profile-tab.text-align-left.row
        a.cursor-pointer.tab-btn.text-align-center.cant-select(
          @click="changeTab(1)",
          v-bind:class="$root.profile_status === 1 ? 'btn-active' : 'btn-noactive'"
        ) 個人資料
        //- a.cursor-pointer.tab-btn.text-align-center.cant-select(
        //-   @click="changeTab(2)",
        //-   v-bind:class="$root.profile_status === 2 ? 'btn-active' : 'btn-noactive'"
        //- ) 活動碼
        //- a.cursor-pointer.tab-btn.text-align-center.cant-select(
        //-   @click="changeTab(3)",
        //-   v-bind:class="$root.profile_status === 3 ? 'btn-active' : 'btn-noactive'"
        //- ) 方案紀錄
    //- 個人資料
    EditProfile(v-if="$root.profile_status === 1")

    //- 活動碼
    ActivityCode(v-if="$root.profile_status === 2")

    //- 方案紀錄
    ProgramRecordMain(
      :plans="plans",
      v-if="$root.profile_status === 3 && program_record_page === 1",
      v-on:goDuePage="goDuePage()"
    )
    ProgramRecordDue(
      :plans="plans",
      v-if="$root.profile_status === 3 && program_record_page === 2",
      v-on:goMainPage="goMainPage()"
    )

  Footer
</template>
<script>
import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import EditProfile from '@/components/profile/edit-profile'
import ActivityCode from '@/components/profile/activity-code'
import ProgramRecordMain from '@/components/profile/program-record-main'
import ProgramRecordDue from '@/components/profile/program-record-due'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    EditProfile,
    ActivityCode,
    ProgramRecordMain,
    ProgramRecordDue
  },
  data () {
    return {
      program_record_page: 1,
      plans: [
        {
          name: '日語月費制',
          //- 確定開始了嗎
          status: false,
          //- 付錢的時間
          payed_at: '2020-01-01 12:12',
          //- 開始時間
          effective_start: '2020-01-01',
          //- 結束時間
          effective_end: '2020-01-31',
          //- 是不是花錢買的 
          order: false,
          //- 是不是過期了
          due_status: false
        },
        {
          name: '美語月費制',
          status: true,
          payed_at: '2020-01-01 12:12',
          effective_start: '2020-01-01',
          effective_end: '2020-01-31',
          order: true,
          due_status: false
        },
        {
          name: 'VVIP月費制',
          status: true,
          payed_at: '2020-01-01 12:12',
          effective_start: '2020-01-01',
          effective_end: '2020-01-31',
          order: false,
          due_status: false
        },
        {
          name: 'VVIP月費制',
          status: true,
          payed_at: '2020-01-01 12:12',
          effective_start: '2020-01-01',
          effective_end: '2020-01-31',
          order: false,
          due_status: true
        },
        {
          name: '美語月費制',
          status: true,
          payed_at: '2020-01-01 12:12',
          effective_start: '2020-01-01',
          effective_end: '2020-01-31',
          order: false,
          due_status: true
        },

      ],
    };
  },
  created () {
    this.program_record_page = 1
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    changeTab (_tab) {
      this.$root.profile_status = _tab
      if (_tab === 3) {
        this.program_record_page = 1
      }
    },
    goDuePage () {
      this.program_record_page = 2
      // 回到頂端
      $('html, body').animate({
        scrollTop: 0
      }, 600)
    },
    goMainPage () {
      this.program_record_page = 1
      $('html, body').animate({
        scrollTop: 0
      }, 600)
    }
  }
}
</script>
<style lang="sass">
</style>
