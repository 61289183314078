<template lang="pug">
//- word-item
.word-item
  .item-all
    //- 中英文
    .sp-item-title
      span {{ item.title }}
      span(v-if="item.kk") {{ item.ch_word }}
    //- kk 英標
    .sp-item-kk(v-if="item.kk")
      span {{ item.kk }}
    //- 詞性
    .sp-item-part
      span.form-type(v-if="item.form") {{ item.form }}
      span.form-text {{ item.ch_word }}
    //- 星星收藏
    img.star-img.cursor-pointer(
      v-if="item.be_kept",
      src="@/assets/image/star@3x.png"
    )
    img.star-img.cursor-pointer(v-else, src="@/assets/image/star-empty@3x.png")
    //- 音訊播放
    .have_media
      img.sound-img.cursor-pointer(src="@/assets/image/sound-a@3x.png")
      //- img.sound-img.cursor-pointer(src="@/assets/image/sound@3x.png")
</template>
<script>
export default {
  props: ['item'],
  name: 'wordItem',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
