import Vue from 'vue'
import Router from 'vue-router'
import home from '@/pages/meta/home'
import homeSecond from '@/pages/meta/home-second'
import history from '@/pages/meta/history'
import program from '@/pages/meta/program'
import about from '@/pages/meta/about'
import article from '@/pages/article/article'
import profile from '@/pages/sub/profile'
import rule from '@/pages/sub/rule'
import collect from '@/pages/sub/collect'
import login_phone from '@/pages/login/login-phone'
import login_scan from '@/pages/login/login-scan'
import signup from '@/pages/signup/signup'
import verification from '@/pages/login/verification'
import notfound from '@/pages/404'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    //- 主畫面
    {
      path: '/web/',
      name: 'home',
      component: home,
    },
    //- 次列表畫面
    {
      path: '/web/category/:categoryId',
      name: 'home-second',
      component: homeSecond,
    },
    //- 文章內頁
    {
      path: '/web/article/:id',
      name: 'article',
      component: article,
    },
    //- 觀看紀錄
    {
      path: '/web/history',
      name: 'history',
      component: history,
    },
    //- 訂閱方案
    {
      path: '/web/plans',
      name: 'program',
      component: program,
    },
    //- 關於我們
    {
      path: '/web/about',
      name: 'about',
      component: about,
    },
    //- 個人資料
    {
      path: '/web/profile',
      name: 'profile',
      component: profile,
    },
    //- 收藏
    {
      path: '/web/favorites',
      name: 'collect',
      component: collect,
    },
    //- 使用條款
    {
      path: '/web/terms',
      name: 'rule',
      component: rule,
    },
    //- 手機號碼登入
    {
      path: '/web/login-phone',
      name: 'login-phone',
      component: login_phone,
    },
    //- 掃描條碼登入
    {
      path: '/web/login-scan',
      name: 'login-scan',
      component: login_scan,
    },
    //- 註冊
    {
      path: '/web/signup',
      name: 'signup',
      component: signup,
    },
    //- 簡訊驗證
    {
      path: '/web/verification',
      name: 'verification',
      component: verification,
    },
    //- 404
    {
      path: '/web/notfound',
      name: 'notfound',
      component: notfound,
    },
  ]
})
