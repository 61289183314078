<template lang="pug">
.rule-content
  .text 親愛的會員，您個人的隱私權，地球村愛學習絕對尊重並予以保護。為了讓您瞭解在使用地球村愛學習所提供各種學習服務時，地球村愛學習是如何蒐集、應用及保護會員提供的個人資訊，請您詳細閱讀地球村愛學習的隱私權保護政策。這份文件將告訴會員，地球村愛學習蒐集的資料、運用方式，以及本網的隱私權保護政策。本政策將視情況變更，請會員定期查詢。
  .title 一、	我們重視您的隱私
  .text.int A、 您在本公司留下的任何資料都是機密，如：個人資料、購買紀錄…等，我們不會對外洩漏您的資料。
  //- .text.int B、 本APP需要綁定您的手機門號，當需要登入時，系統會透過簡訊傳遞一組驗證碼，請會員將此驗證碼正確輸入後，既可使用本APP。
  .text.int B、 沒有經過您的同意，不會將您的個人資料給除了本公司及關係企業之外的任何人。
  //- .text.int D、 您手機內的Cookie會紀錄您在本公司網站裡的資料。這是為了幫助您在瀏覽本公司資料時，電腦可以更快速、效能更好。您登出時，我們就會清空手機Cookie的所有紀錄，以防止下一個使用者以您的帳戶在本公司網站做任何操作。

  .title 二、資料之蒐集及使用方式
  .text.int A、 地球村愛學習需要會員的個人基本資料會請會員提供姓名、電話、郵箱、性別及地區等，我們需要上述資料以便快速而方便的為會員提供服務，並不斷提升地球村愛學習服務系統；以便能提供會員更好的服務。
  .text.int B、 地球村愛學習會利用會員個人識別資料或其他個人資料，用於行銷業務、市場分析、製作報告、學術用途、網路購物及其他電子商務服務及與調查；本公司將藉由洽詢課程、加入會員、交易之過程及其他合於營業登記項目所定業務之需要來蒐集客戶個人資料。
  .text.int C、 為遂行交易行為：會員對商品或勞務為預約、下標、購買、參與贈獎等之活動或從事其他交易時，關於商品配送、勞務提供、價金給付、回覆客戶之詢問、本公司對會員之詢問、相關售後服務及其他遂行交易所必要之業務。
  .text.int D、 宣傳廣告或行銷等：提供會員各種電子雜誌等資訊、透過電子郵件、郵件、電話等提供與服務有關之資訊。將會員所瀏覽之內容或廣告，依客戶之個人屬性或購買紀錄、本公司網站之瀏覽紀錄等項目，進行個人化作業、會員使用服務之分析、新服務之開發或既有服務之改善等。針對民調、活動、留言版等之意見，或其他服務關連事項，與會員進行聯繫。
  .text.int E、 回覆客戶之詢問：針對會員透過電子郵件、郵件、傳真、電話或其他任何直接間接連絡方式向本公司所提出之詢問進行回覆。
  .text.int F、 對於個別服務提供者之資訊提供：會員對服務提供者之商品或勞務為預約、下標、購買、參加贈獎活動或申請其他交易時，本公司於該交易所必要之範圍內，得將會員之個人資料檔案提供予服務提供者，並由服務提供者負責管理該個人資料檔案。詳細內容，請向個別服務提供者洽詢
  .text.int G、 其他：其他符合個資法第20條規定之利用。
  .text.int H、 但在以下的情況下，地球村愛學習仍有權公開會員任何內容、記錄或電子通訊等資料：
  .text.inte 1.依法配合司法檢調機關執行法律上或公務之需求
  .text.inte 2.保護地球村愛學習及其它會員及公眾之權利、材產或個人安全。
  .text.inte 3.因會員之行為違反法規命令或本網會員規章之規定時。
  .text.inte 4.地球村愛學習如發現有不法情事發生時，將主動提供會員相關資料供警調單位處理。

  .title 三、個人資料查詢或更正的方式
  .text 會員對於其個人資料，有查詢、閱覽、製給複製本、補充或更正、停止蒐集、處理或利用、刪除等需求時，可以與本公司客服中心聯絡，本公司將迅速進行處理。
  .text 但請您注意，如拒絕提供加入會員所需必要之資料，將可能導致無法享受本公司完整服務或完全無法使用該項服務。

  .title 四、安全性條款
  .text 為保障會員的隱私及安全，您的個人資料都會由Secure Sockets Layer（SSL）加密方式來處理，因此您的資料在網路上傳送時，是不會被任意攔截或讀取。您可以放心的購買本公司所提供之服務，本公司也不會同意讓他人看到您的交易情況。
  .text 如果您發現您的帳單出現不明消費時，那麼您必須依照您的信用卡公司所訂定的標準程序立即通知他們。在大部分的國家中，信用卡公司不能要求持卡人負擔超過規定的盜刷費用。
  .text 請記得，您在此處所透露的任何資料將成為公開資料。因此，在向任何人提供個人資料時應多加小心。
  .text 在本公司的網站資源中，如果有提供其它與學習有關之網站連結時，當您造訪這些網站，應遵守該隱私權政策及其它相關條款與規定。建議您在提供個人資料之前，請先閱讀這些網站的隱私權及相關聲明。

  .title 五、隱私權保護政策修訂
  .text 本公司的隱私權保護政策將因應需求隨時修正，修正後的條款將刊登於網站上。會員如果對於本公司網站隱私權聲明、或與個人資料有關之相關事項有任何疑問，可洽詢本公司客服中心聯絡。
  .text 如您對我們的隱私權政策有任何想法或建議，歡迎與我們聯繫。
</template>
<script>
export default {
  name: 'privacy-rule',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
    };
  }
}
</script>
