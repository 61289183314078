<template lang="pug">
//- 首頁
.about-view.page-index
  homeHeader
  .page-content
    .container-fluid-sm
      .about-box
        img.logo-hr(src="@/assets/image/hr-logo@3x.png")
        img.logo(src="@/assets/image/head-logo.png")
        .about-title APP 6大介紹
        img(src="@/assets/image/intro-1.png")
        .about-title 10大類 46項主題
        img(src="@/assets/image/intro-2.png")
        .about-title 檢定榮譽榜
        img(src="@/assets/image/1@3x.png")
        img(src="@/assets/image/2@3x.png")
        img(src="@/assets/image/3@3x.png")
        img(src="@/assets/image/4@3x.png")
        img(src="@/assets/image/5@3x.png")
        img(src="@/assets/image/6@3x.png")
        img(src="@/assets/image/7@3x.png")
        img.logo-hr(src="@/assets/image/hr-logo@3x.png")
  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import ArticleItem from '@/common/article-item'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    ArticleItem,
  },
  data () {
    return {
    };
  },
  created () {
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
