<template lang="pug">
//- 註冊畫面
.sginup-view
  .container-fluid-sp
    .row
      .col-lg-6.col-md-12.text-align-right
        //- 返回按鈕
        .bcak-box.d-block.d-lg-none
          .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
            @click="$root.goHome()"
          )
            span 返回首頁
            img.back-img(src="@/assets/image/btn-back@3x.png")

        //- 註冊表單
        .form.text-align-left
          .form-logo
            img(src="@/assets/image/head-logo.png")
          .from-title
            span 用戶註冊
            .form-title-line
          .from-text
            p 填入資料啟用手機驗證。
          .form-group
            //- 手機號碼input
            .form-group-title 手機號碼
            .form-input-group
              .phonecheck-input.form-group-input
                .from-img-text.float-left
                  img.selectimg(
                    v-if="$root.country_code === '+886'",
                    src="@/assets/image/ic-tw@3x.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+1'",
                    src="@/assets/image/ic-usa.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+61'",
                    src="@/assets/image/ic-australia.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+64'",
                    src="@/assets/image/ic-nz.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+44'",
                    src="@/assets/image/ic-en.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+84'",
                    src="@/assets/image/ic-vietnam.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+86'",
                    src="@/assets/image/ic-ch.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+852'",
                    src="@/assets/image/ic-hk.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+853'",
                    src="@/assets/image/ic-macao.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+81'",
                    src="@/assets/image/ic-jp.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+82'",
                    src="@/assets/image/ic-kor.png"
                  )
                  img.selectimg(
                    v-if="$root.country_code === '+60'",
                    src="@/assets/image/ic-malaysia.png"
                  )
                  select.phoneselect.padding-none(v-model="$root.country_code")
                    option(
                      v-for="(code, index) in $root.country_codes",
                      :value="code"
                    ) {{ code }}
                  .clearfix
                input.phoneinput.float-left(
                  type="tel",
                  placeholder="輸入您的手機號碼",
                  v-model="cellphone",
                  spellcheck="false"
                )

            //- 姓名input
            .form-input-group
              .form-group-title 姓名
              input.form-group-input(type="text", placeholder="輸入您的姓名")

            //- 信箱input
            .form-input-group
              .form-group-title 信箱
              input.form-group-input(type="text", placeholder="輸入您的信箱")

            //- 性別select
            .form-input-group
              .form-group-title 性別
              select.form-group-input
                option 選擇您的性別
                option 男性
                option 女性
              img.select-drop-down-img(
                src="@/assets/image/ic-arrown-down@3x.png"
              )

            //- 所在區select
            .form-input-group
              .form-group-title 所在區
              select.form-group-input
                option 選擇您的所在區
                option(v-for="(city, index) in citys", :value="city.id") {{ city.city }}
              img.select-drop-down-img(
                src="@/assets/image/ic-arrown-down@3x.png"
              )

            //- 同意條款
            .rule-check-box
              img.check-img.cursor-pointer(
                v-if="check_status",
                src="@/assets/image/check-on.png",
                @click="changeCheck()"
              )

              .check-box.cursor-pointer(v-else, @click="changeCheck()")
              .rule-text
                span.gray 勾選已表示愛學習
                span.green.cursor-pointer 隱私權及個資保護聲明
                //- span.gray 與
                //- span.green 使用者條款

            //- 註冊按鈕
            button.button.btn-default.form-group-button.w-100(
              @click="$root.useAlert(1, '測試標題', '測試警告內文', '測試按鈕文字')"
            )
              //- (
              //-   @click="$root.goVer()"
              //- )
              span 註冊
              img.form-group-button-backimg(
                src="@/assets/image/btn-next@3x.png"
              )

            //- ps
            .from-ps-box
              span.from-ps-text 已經有地球村美日韓語帳號？
              span.from-ps-button.cursor-pointer(@click="$root.goPhoneLogin()") 前往登入

      //- large-時出現
      .col-lg-6.text-align-right.d-lg-block.d-none
        //- 返回按鈕
        .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
          @click="$root.goHome()"
        )
          span 返回首頁
          img.back-img(src="@/assets/image/btn-back@3x.png")
        //- 大圖
        img.sign-img-lg(src="@/assets/image/sign-img.png")
        //- 連結群
        ConnetGroup

      //- large以外時出現
      .col-md-12.d-block.d-lg-none
        //- 大圖
        img.sign-img-md(src="@/assets/image/sign-img.png")
        //- 連結群
        ConnetGroup
</template>
<script>
import $ from 'jquery'
import ConnetGroup from '@/common/connet-group'
export default {
  name: 'signup',
  components: {
    ConnetGroup
  },
  data () {
    return {
      country_code: '+886',
      cellphone: '',
      check_status: true,
      citys: [
        {
          id: 1,
          city: "臺北市"
        },
        {
          id: 2,
          city: "新北市"
        },
        {
          id: 3,
          city: "桃園市"
        },
        {
          id: 4,
          city: "臺中市"
        },
        {
          id: 5,
          city: "臺南市"
        },
        {
          id: 6,
          city: "高雄市"
        },
        {
          id: 7,
          city: "新竹縣"
        },
        {
          id: 8,
          city: "苗栗縣"
        },
        {
          id: 9,
          city: "彰化縣"
        },
        {
          id: 10,
          city: "南投縣"
        },
        {
          id: 11,
          city: "雲林縣"
        },
        {
          id: 12,
          city: "嘉義縣"
        },
        {
          id: 13,
          city: "屏東縣"
        },
        {
          id: 14,
          city: "宜蘭縣"
        },
        {
          id: 15,
          city: "花蓮縣"
        },
        {
          id: 16,
          city: "臺東縣"
        },
        {
          id: 17,
          city: "澎湖縣"
        },
        {
          id: 18,
          city: "金門縣"
        },
        {
          id: 19,
          city: "連江縣"
        },
        {
          id: 20,
          city: "基隆市"
        },
        {
          id: 21,
          city: "新竹市"
        },
        {
          id: 22,
          city: "嘉義市"
        },
        {
          id: 23,
          city: "其他地方"
        }
      ]
    };
  },
  mounted () {
  },
  computed: {
  },
  created () {
    this.loadSign()
    this.$root.closeLoading()
  },
  methods: {
    loadSign () {
      console.log('====== 載入監測 phone input =======')
      // == 針對鍵盤聚焦進行特殊處理 ==
      $(document).on('focus', '.phoneinput', function () {
        $('.phonecheck-input').css({ 'box-shadow': '0 0 .2rem .1rem #5468ff44' })
      })
      // == 針對鍵盤失焦進行特殊處理 ==
      $(document).on('blur', '.phoneinput', function () {
        $('.phonecheck-input').css({ 'box-shadow': '0 2px 5px 0 rgba(126, 136, 149, 0.12)' })
      })
    },
    //- 切換勾選狀態
    changeCheck () {
      this.$data.check_status = !this.$data.check_status
    },
  }
}
</script>
<style lang="sass">
</style>
