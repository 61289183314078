<template lang="pug">
//- 驗證畫面
.verification-view
  .container-fluid-sp
    .row
      .col-lg-6.col-md-12.text-align-right
        //- 返回按鈕
        .bcak-box.d-block.d-lg-none
          .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
            @click="$root.goHome()"
          )
            span 返回首頁
            img.back-img(src="@/assets/image/btn-back@3x.png")

        //- 註冊表單
        .form.text-align-left
          .form-logo
            img(src="@/assets/image/head-logo.png")
          .from-title
            span 手機驗證
            .form-title-line
          .from-text
            p 我們給您發了驗證訊息
            p 驗證您的手機號碼
          .form-group
            //- 驗證號碼
            .form-input-group
              .form-group-title 驗證碼
              input.form-group-input-ver(
                type="tel",
                maxlength="6",
                placeholder="輸入您的驗證碼",
                v-model="confrimCode"
              )

            //- 註冊按鈕
            button.button.btn-default.form-group-button.w-100(
              @click="confirmPhoneCode()"
            )
              span 驗證
              img.form-group-button-backimg(
                src="@/assets/image/btn-next@3x.png"
              )

            //- ps
            .from-ps-box
              span.from-ps-text 您沒有收到簡訊？
              span.form-ps-verify-time(v-if="last_time >= 1") {{ last_time }}秒倒數
              span.from-ps-button.cursor-pointer(v-else) 重新傳送

      //- large-時出現
      .col-lg-6.text-align-right.d-lg-block.d-none
        //- 返回按鈕
        .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
          @click="$root.goHome()"
        )
          span 返回首頁
          img.back-img(src="@/assets/image/btn-back@3x.png")
        //- 大圖
        img.login-img-lg(src="@/assets/image/login-img.png")
        //- 連結群
        ConnetGroup

      //- large以外時出現
      .col-md-12.d-block.d-lg-none
        //- 大圖
        img.login-img-md(src="@/assets/image/login-img.png")
        //- 連結群
        ConnetGroup
</template>
<script>
// import $ from 'jquery'
import ConnetGroup from '@/common/connet-group'
export default {
  name: 'signup',
  components: {
    ConnetGroup
  },
  data () {
    return {
      confrimCode: '',
      last_time: 90,
    };
  },
  mounted () {
  },
  computed: {
  },
  created () {
    this.$options.interval = setInterval(this.runbyeverytime, 1000)
    this.$root.closeLoading()
  },
  methods: {
    runbyeverytime () {
      // 處理倒數計時秒數 90
      if (this.$data.last_time >= 1) {
        // console.log('--- runbyeverytime ---')
        this.$data.last_time = this.$data.last_time - 1
        // console.log('--> time: ' + this.$data.last_time)
      }
    },
    // 請求驗證簡訊編號
    confirmPhoneCode () {
      let phoneNumber = this.$root.cellphoneStep
      let code = this.confrimCode

      this.$root.confirmPhoneNumberCode(phoneNumber, code)
    }
  }
}
</script>
<style lang="sass">
</style>
