<template lang="pug">
//- 首頁
.collect-view.page-index
  homeHeader
  .page-content
    .container-fluid-sm
      .title-content.text-align-left 我的收藏
      .collect-tab.text-align-left.row
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          @click="changeType('article')",
          v-bind:class="collect_type === 'article' ? 'btn-active' : 'btn-noactive'"
        ) 文章
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          @click="changeType('word')",
          v-bind:class="collect_type === 'word' ? 'btn-active' : 'btn-noactive'"
        ) 單字
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          @click="changeType('sentence')",
          v-bind:class="collect_type === 'sentence' ? 'btn-active' : 'btn-noactive'"
        ) 句型
    //- 文章
    .container-fluid-sm(v-if="collect_type === 'article'")
      //- .have-not-collect
        img.nocollectimg(src="@/assets/image/readimg@3x.png")
        .nocollect-text 目前未收藏您的喜愛的
        .nocollect-text 快去看文章收藏！
      .have-collect
        .home-listcontent.row.align-items-stretch
          ArticleItem.home-list-item.cursor-pointer.col-lg-sp.col-md-3.col-sm-4.col-6(
            v-for="item in $root.articleList",
            :item="item",
            :list="false"
          )
    //- 單字
    .container-fluid-sm(v-if="collect_type === 'word'")
      .have-not-collect(v-if="collect_word_list.length < 1")
        img.nocollectimg(src="@/assets/image/readimg@3x.png")
        .nocollect-text 目前未收藏您的喜愛的
        .nocollect-text 快去看文章收藏！
      .have-collect(v-eles)
        .home-listcontent.row.align-items-stretch
          WordItem.col-lg-4.col-md-6(
            v-for="item in collect_word_list",
            :item="item"
          )
    //- 句型
    .container-fluid-sm(v-if="collect_type === 'sentence'")
      .have-not-collect(v-if="collect_sentence_list.length < 1")
        img.nocollectimg(src="@/assets/image/readimg@3x.png")
        .nocollect-text 目前未收藏您的喜愛的
        .nocollect-text 快去看文章收藏！
      .have-collect(v-eles)
        .home-listcontent.row.align-items-stretch
          WordItem.col-lg-4.col-md-6(
            v-for="item in collect_sentence_list",
            :item="item"
          )

  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import ArticleItem from '@/common/article-item'
import WordItem from '@/common/word-item'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    ArticleItem,
    WordItem
  },
  data () {
    return {
      collect_type: 'article',
      collect_word_list: [
        {
          title: 'language',
          ch_word: '語言',
          kk: '[1famvpoq]',
          form: 'n.',
          be_kept: false,
        },
        {
          title: 'world',
          ch_word: '世界',
          kk: '[1famvpoq]',
          form: 'n.',
          be_kept: false,
        },
        {
          title: 'beautiful',
          ch_word: '美麗的',
          kk: '[1famvpoq]',
          form: 'adj.',
          be_kept: false,
        },
        {
          title: 'worry',
          ch_word: '擔心',
          kk: '[1famvpoq]',
          form: 'v.',
          be_kept: false,
        }
      ],
      collect_sentence_list: [
        {
          title: 'tip the scales',
          ch_word: '起決定性的作用;扭轉局勢',
        },
        {
          title: 'tip the scales',
          ch_word: '起決定性的作用;扭轉局勢',
        }
      ],
    };
  },
  created () {
    // this.$root.hide_page_status = true
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    changeType (_type) {
      this.collect_type = _type
    }
  }
}
</script>
<style lang="sass">
</style>
