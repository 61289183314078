<template lang="pug">
.normal-alert-view
  .alert-title {{ $root.alert.title }}
  .alert-text {{ $root.alert.text }}
  .alert-button-group
    button.button.btn-default.purple-btn.btn-width-auto.alert-btn(
      @click="$root.closeAlert()"
    )
      span {{ $root.alert.button }}
  .clearfix
</template>
<script>
// import $ from 'jquery'
export default {
  name: '',
  props: [],
  components: {},
  data () {
    return {

    };
  },
  mounted () {

  },
  computed: {

  },
  methods: {

  }
}
</script>
