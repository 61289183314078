<template lang="pug">
//- 文章內頁
.article-view.page-index
  homeHeader
  .container-fluid
    .row
      .col-lg-6.article-left.col-md-12.col-sm-12.flex-grow-0.flex-shrink-0
        .video-all
          //- 影片畫面
          Video(
            :article="article",
            :currentMedia="currentMedia",
            ref="videoPlayer"
          )
          //- 影片文章控制器
          VideoControl(v-if="article.type > 0", :article="article")
        .d-lg-block.d-none
          .article-left
            //- 文章補充
            ArticleSup(:sp_list="spList", :specialMode="isSpecialModel")
      .col-lg-6.col-md-12.col-sm-12.flex-grow-0.flex-shrink-0.padding-none
        //- 文章的title及數據
        ArticleTitle(:article="article")
        //- 文章文字部份
        ArticleText(
          :article="article",
          @changePara="changeParaContent",
          @playTargetTag="playTargetTag",
          ref="articleText"
        )
</template>
<script>
import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Video from '@/components/article/video'
import VideoControl from '@/components/article/video-control'
import ArticleTitle from '@/components/article/article-title'
import ArticleSup from '@/components/article/article-sup'
import ArticleText from '@/components/article/article-text'
import GoTopButton from '@/common/go-top-button'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Video,
    VideoControl,
    ArticleTitle,
    ArticleSup,
    ArticleText,
    GoTopButton,
  },
  data () {
    return {
      articleId: '',
      article: {},
      currentMedia: false,
      //- 音檔播放狀態
      play_sound_status: false,
      //- 補充段落資訊
      spList: [],
      play_media: {},
      isSpecialModel: false,
      sp_list: [
        {
          title: 'language',
          ch_word: '語言',
          kk: '[1famvpoq]',
          form: 'n.',
          be_kept: false,
        },
        {
          title: 'tip the scales',
          ch_word: '起決定性的作用;扭轉局勢',
        },
        {
          title: 'world',
          ch_word: '世界',
          kk: '[1famvpoq]',
          form: 'n.',
          be_kept: false,
        },
        {
          title: 'beautiful',
          ch_word: '美麗的',
          kk: '[1famvpoq]',
          form: 'adj.',
          be_kept: false,
        },
        {
          title: 'worry',
          ch_word: '擔心',
          kk: '[1famvpoq]',
          form: 'v.',
          be_kept: false,
        }
      ],
      // 目前播放時間
      nowTime: false,
      // 目前位置
      nowLocation: 0
    };
  },
  mounted () {
  },
  watch: {
    '$root.article_ready' (_status) {
      if (_status) {
        this.checkSystem()
      }
    },
    '$root.play_status' (_status) {
      if (_status) {
        this.$refs.videoPlayer.startVideo()
      } else {
        this.$refs.videoPlayer.stopVideo()
      }
    },
    '$root.article_switch' () {
      // 關閉現在的播放
      this.$root.play_status = false
      // 重新載入文章
      this.initArticle()
    }
  },
  async created () {
    this.articleId = this.$route.params.id
    await this.checkSystem()
    // 關閉選單
    this.$root.hide_page_status = true
  },
  computed: {
  },
  methods: {
    // 確認系統狀況
    async checkSystem () {
      var vm = this
      if (!this.$root.article_ready) {
        return
      }
      // 預防性防止重整刷新
      if (!this.$root.article_id) {
        await this.$root.goArticle(this.articleId, true, true)
      }
      // 載入文章資料
      await this.initArticle()
      // 啟用文章監聽功能
      if (this.article.type > 0) {
        this.setTimeCheckMethod01 = setInterval(function () { vm.confirmPlayLenght() }, 300);
        this.setTimeCheckMethod02 = setInterval(function () { vm.checkWhereAre() }, 500);
      }
      // 關閉載入中
      this.$root.closeLoading()
    },
    // 載入文章資訊
    async initArticle () {
      var vm = this
      console.log('--> 啟動文章內頁...')

      // 獲取文章資料
      let article = JSON.parse(JSON.stringify(this.$root.currentArticle))
      console.log('--> 文章段落深拷貝結果: ', article)

      // 獲取文章資料
      let article_paras = JSON.parse(JSON.stringify(article.article_paras))
      console.log('--> 文章段落深拷貝結果: ', article_paras);

      // 逐一處理分段
      article_paras.forEach(function (_para, _index) {
        vm.$root.currentArticle.article_paras[_index].click_statu = false
      })

      // 準備進行重組
      let articleParas = []

      // 只針對解說模式
      if (this.$root.article_switch) {
        let paraCnt = -1
        console.log('--> 進入解說模式的分段處理...')
        article_paras.forEach(function (_para, _index) {
          if (_para.commentary_at > 0) {
            // 指針轉移到下一個
            paraCnt += 1
            // 建立新的段落
            articleParas.push($.extend(true, {}, _para))
            // 檢視結果
            console.log('');
            console.log('------------->')
            console.log('--> 整合第[ ' + paraCnt + ' ]段落...');
            console.log('  -->合併原[' + _index + ']段: ', _para);
          } else {
            // 逐一對應目標內容
            _para.paraContents.forEach(function (_paraContent) {
              if (_paraContent.lang_type === 4) {
                articleParas[paraCnt].content_zh.content += ('<br><br>' + _paraContent.content)
              } else {
                articleParas[paraCnt].content.content += ('<br><br>' + _paraContent.content)
              }
            })
            // 處理單詞的合併
            _para.words.forEach(function (_word) {
              articleParas[paraCnt].words.push(_word)
            })
            console.log('  -->合併原[' + _index + ']段: ', articleParas[paraCnt].paraContents);
          }
        })
      } else {
        console.log('--> 進入閱讀模式的分段處理...')
        articleParas = article_paras
      }

      // 處理段落資料
      article_paras.forEach(async function (_para, _index) {
        article_paras[_index].image_url = ''
        if (_para.image_id) {
          article_paras[_index].image_url = await vm.$root.common.getMediaPreviewUri(_para.image_id, true)
        }
      })

      // 處理文章資料
      article.article_paras = articleParas

      // 調整音頻文章的參數
      if (article.type === 1) {
        // 處理朗讀參數
        if (article.read_aloud_id) {
          article.read_aloud_url = {
            url: await vm.$root.common.getMediaPreviewUri(article.read_aloud_id, true),
            ext: article.read_aloud_ext
          }
        }
        // 處理解說參數
        if (article.commentary_id) {
          article.commentary_url = {
            url: await vm.$root.common.getMediaPreviewUri(article.commentary_id, true),
            ext: article.read_aloud_ext
          }
        }
      }

      // 調整視頻文章的參數
      if (article.type === 2) {
        // 處理朗讀參數
        if (article.read_aloud_id) {
          article.read_aloud_url = await vm.$root.getAttMediaUriByHLS(article.read_aloud_id)
        }
        // 處理解說參數
        if (article.commentary_id) {
          article.commentary_url = await vm.$root.getAttMediaUriByHLS(article.commentary_id)
        }
      }

      // 處理目標媒體檔案
      this.currentMedia = {}
      if (this.$root.article_switch) {
        this.currentMedia = article.commentary_url
      } else {
        this.currentMedia = article.read_aloud_url
      }
      console.log('--> 本次處理媒體檔案: ', this.currentMedia)

      // 塞回文章資料....
      this.article = article
      console.log('--> 處理完成的文章資料: ', this.article)

      this.$root.play_status = false

      setTimeout(() => { vm.$forceUpdate() }, 200)

      // 通報後端開啟文章
      this.watchArticle()

      // 確認是否開啟解說
      if (article.commentary_id) {
        article.swich_commentary_status = true
      }
      // 是否開啟朗讀
      if (article.read_aloud_id) {
        article.swich_readaloud_status = true
      }

      // 驗證特殊類別
      article.categoriesObj.forEach(function (_item) {
        // 驗證是否有特殊類別
        console.log('--> 本次驗證父類別: ', _item.parent_id)
        if (vm.$root.specialTyeps.indexOf(_item.parent_id) > -1) {
          vm.isSpecialModel = true
        }
      })

      // 灌入整理完成的資料
      this.article = article

      // 啟動顯示文章
      setTimeout(() => { this.showcontent = true }, 300)
    },
    // 通報後端開啟文章
    watchArticle (_start = true) {
      if (!this.article.id) {
        return false
      }

      // 通報後端文章觀看紀錄
      this.$http.post('api/member/view', {
        is_start: _start,
        type: 0,
        article_id: this.article.id
      })
    },
    //- 播放音檔
    startPlaySound () {
      if (!this.play_media.url) {
        this.$root.common.naviteAlert('發生問題', '這個單字目前沒有對應的聲音')
        return false
      }

      this.$refs.WordPlayer.startVideo()
    },
    //- 停止音檔
    stopPlaySound () {
      this.$refs.WordPlayer.stopVideo()
    },
    //- 確認播放進度
    confirmPlayLenght () {
      if (this.$root.play_status && this.$refs.videoPlayer) {
        let totalTime
        let currentTime
        // 解說模式
        if (this.use_commentary_model) {
          totalTime = this.article.commentary_period.duration
        }
        // 朗讀模式
        else {
          totalTime = this.article.period.duration
        }
        // 取得目前播放時間
        currentTime = this.$refs.videoPlayer.getNowTime()
        // console.log('--> 音視頻總秒數: ' + totalTime + ', 目前播放時間: ', currentTime)
        // console.log('--> 目前百分比: ' + (currentTime / totalTime) * 100)
        this.play_sound_persent = (currentTime / totalTime) * 100
        // console.log(this.play_sound_persent);
      }
    },
    // 秒數轉時分秒
    formatSecond (secs) {
      var hr = Math.floor(secs / 3600);
      var min = Math.floor((secs - (hr * 3600)) / 60);
      var sec = parseInt(secs - (hr * 3600) - (min * 60));

      // while (min.length < 2) { min = '0' + min; }
      // while (sec.length < 2) { sec = '0' + min; }
      // if (hr) hr += ':';
      return this.$root.paddingLeft(hr.toString(), 2) + ':' + this.$root.paddingLeft(min.toString(), 2) + ':' + this.$root.paddingLeft(sec.toString(), 2)
    },
    //- 找出目前應在位置
    checkWhereAre (_needGoToLocation = false) {
      // 必須為正在播放中
      if (this.$root.play_status && this.$refs.videoPlayer) {
        // 獲取目前播放時間
        let currentTime = this.$refs.videoPlayer.getNowTime()
        this.nowTime = this.formatSecond(currentTime)
        // console.log('')
        // console.log('-------------->');
        // console.log('---> 目前播放時間: ', currentTime)

        let vm = this
        let nowLocation = 0
        let tmpLocation = vm.nowLocation
        this.article.article_paras.forEach(function (_para, _index) {
          // 朗讀模式
          if (!vm.$root.article_switch) {
            if (_para.read_aloud_at) {
              _para.read_aloud_at = (_para.read_aloud_at) ? _para.read_aloud_at : 0
              if (currentTime > _para.read_aloud_at) {
                nowLocation = (_index + 1)
              }
              // console.log('---> 正在執行朗讀模式的所在位置: ', nowLocation)
            }
          }
          // 解說模式
          else {
            if (_para.commentary_at) {
              _para.commentary_at = (_para.commentary_at) ? _para.commentary_at : 0
              if (currentTime > _para.commentary_at) {
                nowLocation = (_index + 1)
              }
              // console.log('---> 正在執行解說模式的所在位置: ', nowLocation)
            }
          }
        })
        vm.nowLocation = nowLocation

        // 必須大於 0 才能開始
        if ((nowLocation > 0 && tmpLocation !== nowLocation) || _needGoToLocation) {
          // console.log('--> 進入準備跳耀階段...')
          if (this.$root.repeat_status) {
            vm.nowLocation = tmpLocation
            // console.log('--> 前往返回位置:', tmpLocation);
            this.$refs.articleText.jumpContent(tmpLocation - 1, true)
          } else {
            // console.log('--> 目前應在位置: ', vm.nowLocation)
            this.$refs.articleText.jumpContent(vm.nowLocation - 1, false)
          }
        }
      }
    },
    // 請求播放指定時間的音視頻
    playTargetTag (_time = '') {
      this.$refs.videoPlayer.playTargetTime({ setTime: _time })
    },
    //- 更換被選取的段落
    changeParaContent (_index) {
      console.log('--> 觸發更換新段落的補充資訊: ' + JSON.stringify(this.article.article_paras[_index].words))
      this.spList = this.article.article_paras[_index].words
    }
  }
}
</script>
<style lang="sass">
</style>
