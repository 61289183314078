<template lang="pug">
//- article-item
.article-item
  .card.padding-none
    //- 鎖
    .lock-bg(v-if="!item.watchable")
      .lock-group
        img.lock-img(src="@/assets/image/lock@3x.png")
        .lock-text 此文章尚未解鎖，您可以試閱免費文章或購買方案來解鎖學習

    //- 沒鎖
    .click-bg(
      v-if="item.watchable",
      @click="$root.goArticle(item.id, item.watchable)"
    )
      .click-group

    //- 星星們
    .star-group(v-if="item.watchable")
      //- img.star-img.float-right(src="@/assets/image/star-empty@3x.png")
      img.star-img.float-right(src="@/assets/image/star@3x.png")
      span.star-num.float-right {{ item.likes }}

    //- free
    img.free-img(v-if="item.is_free", src="@/assets/image/free.png")

    //- demo
    img.free-img(v-if="item.is_demo", src="@/assets/image/demo.png")

    //- 主體
    .card-body.padding-none.img-content
      img(:src="item.image_url")
    .card-body.padding-sm.detail-content
      .card-top
        .float-left.font-weight-bold.card-top-text.flex1 {{ item.class }}
        .float-right
          span.card-top-level1.card-top-level(v-if="item.level === '初級'") {{ item.level }}
          span.card-top-level2.card-top-level(v-if="item.level === '中級'") {{ item.level }}
          span.card-top-level3.card-top-level(v-if="item.level === '高級'") {{ item.level }}
          span.card-top-level3.card-top-level(v-if="item.level === '優級'") {{ item.level }}
        .clearfix
      p.card-en-title {{ item.title }}
      .card-bottom
        p.card-ch-title.float-left {{ item.title_zh }}
        //- .card-read
          .read-num 1.5k
          img.read-img(src="@/assets/image/el-ear.png") 
          .clearfix
        //-月刊日期
        .card-date(v-if="item.magazine_date && !item.is_demo")
          img.date-img(src="@/assets/image/calendar.png")
          .date-num {{ item.magazine_date }}
          .clearfix
        .clearfix
</template>
<script>
export default {
  props: ['item', 'list'],
  name: 'articleItem',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
