<template lang="pug">
.rule-content
  .text 歡迎您成為”地球村愛學習”的會員(免費與付費會員均適用)，地球村愛學習是由「地球村語文資訊有限公司」(以下簡稱本公司)建置並維護。當您註冊完成時，即視為您已經審閱3日以上，並且同意地球村愛學習服務條款(以下簡稱本服務條款)之內容，本公司將依據本服務條款之契約內容，為您提供服務。請您在使用前確實詳加審閱本服務條款之內容，如果您不同意本服務條款內容，請您立即停止使用”地球村愛學習”的服務。
  .title 法定代理人
  .text 如您是未滿二十歲之未成年人，您必須在加入會員前透過法定代理人或監護人閱讀並得到其同意，才可以註冊及使用"地球村愛學習"所提供之會員服務。當您使用"地球村愛學習"所提供之服務時，則視為您的法定代理人或監護人已了解並同意本服務條款。

  .title 服務內容
  .text 地球村愛學習提供會員美日韓語會話的文章、音頻、視頻，地球村實體課程的內容，與美日語的聽力檢定練習。

  .title 使用權的範圍
  .text 本公司授權會員依本服務條款之約定，對於本服務享有非專屬之使用權。未經本公司事前之書面同意，會員不得將其會員資格使用權轉讓、轉借、轉售他人使用，否則本公司有權取消會員資格，不得再使用地球村愛學習APP。
  .text 地球村愛學習之各項資料均屬本公司版權所有，是以會員不得將地球村愛學習APP上任何資料轉用它處，一經發現依法處理。

  .title 使用權的取得
  .text 依註冊程序登錄並經本公司完成審核設定後，即可取得會員之資格，並可開始使用本服務。
  .text 本公司為了完善系統服務，有權增加、調整、變更或取消系統或功能之全部或部分之權利。

  .title 授權使用費
  .text 地球村愛學習提供會員依據不同的活動方案，付費會員自付款至付費方案截止日期間內不得中途中止。活動方案截止後，付費會員自動轉換為免費會員，活動方案截止前所享有的權限在活動方案截止後將無法使用，也無法延續，所稱享有的權限包括但不限於付費觀看的月刊完整音視頻文字內容、付費觀看的叢書完整音視頻文字內容、付費觀看的檢定音視頻文字內容、付費觀看的兒少音視頻文字內容等。
  .text 本公司擁有對活動方案的最終解釋權。
  .text 活動方案的月費制將會由第三方公司每月透過會員設定的信用卡資訊進行扣款。若會員欲停止月費制的活動方案，需在第三方扣款前7個工作日電話通知本公司進行停止。如有任何疑問請撥打客服專線02-5556-1111 (週一至週五09：30 ~ 21：30 )，將有專人為您服務。

  .title.redcol 服務中斷的處理
  .text.int 1、本公司於下列情形之一時，得暫停或中斷本服務之全部或部分，對於會員不負賠償責任：
  .text.inte (1)相關電信業電子通信設備發生故障導致中斷。
  .text.inte (2)甲方相關系統設備損壞維修、維護或更換等導致中斷。
  .text.inte (3)因天災或不可抗力之因素造成本公司之服務停止或中斷。
  .text.int 2、會員違反本服務條款或法令，或有不可歸責於本公司之事由，而造成本服務之全部或部份暫停或中斷時，該期間之費用仍需依正常標準計費及繳納。

  .title.redcol 不得退費規定
  .text 付費會員在付費完成時即代表會員同意並接受活動方案之價格與服務內容，因此當完成付費程序後，會員同意本公司將不再提供任何退費或等值之補償服務。

  .title 授權使用規則
  .text 下列情形之一者，由會員自負一切法律責任，本公司有權立即終止本服務條款，會員無條件接受並不得拒絕：
  .text.int 1、	會員擅自破壞、修改本公司網路內容或散佈干擾本公司網路系統之電腦程式(含病毒)。
  .text.int 2、	會員於地球村愛學習APP中不得發佈有性別、種族、政治、宗教岐視或散佈不實、不當(恐嚇、毀謗、侵犯他人隱私、色情或其他違反公序良俗…等)之言論、文字、圖片、影像。
  .text.int 3、	會員利用本服務從事不法或違反本合約約定事項之行為。

  .title 會員責任
  .text 地球村愛學習提供會員依據不同的活動方案服務
  .text.int 1、會員不得利用地球村愛學習之會員服務，進行任何商業行為或以任何與中華民國法律牴觸之非法使用行為。
  .text.int 2、會員如有上述行為致他人權益受損時，須自負相關法律責任。如本公司發現會員有違反上述各項情事時，本公司除有權移除或刪除該內容外，並得以終止或暫停該會員之資格及各項會員服務。本公司如因此產生損害時，得依法向該會員請求賠償。
  .text.int 3、會員對於其使用者帳號有妥善保管以避免第三人知悉之義務。雙方當事人之任何一方，如發現會員之使用者帳號遭第三人非法使用，應立即通知對方，經會員確認有遭第三人非法使用之情事，本公司應立即暫停該使用者帳號之使用。

  .title 擔保責任之免除
  .text 本公司各項會員服務，依本公司規劃提供，對於特定會員之特殊需求，本公司不擔保本服務將符合您的所有需求。
  .text 本公司不擔保各項服務之正確、完整、安全、可靠、合適、時效、穩定、不會斷線及不會出錯，您同意對於您所傳送之檔案及資料可斟酌自行備份。如因傳送而造成檔案及其他資料之遺失，本公司不負任何責任。

  .title 智慧財產權
  .text 本網站上所使用或提供之軟體、程式及內容之專利權、著作權、商標權、營業秘密、專門技術及其他智慧財產權均屬本公司或各該權利人所有，非本公司提供之分享功能或書面授權同意，會員不得進行重製、公開傳播、公開播送、公開上映、改作、編輯、出租、散佈、進行還原工程、解編、反向組譯、或其他方式之使用，亦不得轉做任何商業用途。如有違反，除應自負法律責任外，本公司得向該會員或使用單位請求損害賠償，並得提出民、刑事訴訟。

  .title 管轄法院
  .text 因本服務條款所生之一切爭議，以台北地方法院為訟訴之法院。
</template>
<script>
export default {
  name: 'use-rule',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
    };
  }
}
</script>
