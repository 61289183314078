<template lang="pug">
//- ProgramRecordDue
.container-fluid-xs
  //- 訂閱紀錄
  .program-record-all
    //- 返回按鈕
    .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
      @click="goMainPage()"
    )
      img.back-img(src="@/assets/image/btn-back@3x.png")
      span 返回方案紀錄

    .title-content.text-align-left 訂閱項目
    //- 已到期
    .program-section
      .program-sm-title 已到期
      ProgramRecordItem(
        v-if="plan.due_status",
        v-for="(plan, index) in plans",
        :plan="plan"
      )
</template>
<script>
import ProgramRecordItem from '@/common/program-record-item'
export default {
  name: 'ProgramRecordDue',
  props: ['plans'],
  components: {
    ProgramRecordItem
  },
  computed: {

  },
  created () {
  },
  data () {
    return {

    };
  },
  methods: {
    goMainPage () {
      this.$emit('goMainPage')
    }
  }
}
</script>
