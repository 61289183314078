<template lang="pug">
//- login-phone
.login-view
  .container-fluid-sp
    .row
      .col-lg-6.col-md-12.text-align-right
        //- 返回按鈕
        .bcak-box.d-block.d-lg-none
          .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
            @click="$root.goHome()"
          )
            span 返回首頁
            img.back-img(src="@/assets/image/btn-back@3x.png")

        //- 登入表單
        .form.text-align-left
          .form-logo
            img(src="@/assets/image/head-logo.png")
          .from-title
            span 用戶登入
            .form-title-line
          .from-text
            p 透過行動條碼登入
          .form-group
            //- QR CODE 
            .code-box
              qrcode.code-size(:value="accessToken")
              //- 重整按鈕
              .code-refresh-btn.cursor-pointer(@click="getLoginAccessToken()")
                img.code-refresh-img(src="@/assets/image/refresh@3x.png")
                .code-refresh-text 更新行動條碼

            //- 文字描述
            .form-des.text-align-center
              p.form-des-text 請開啟Android／iOS版本的
              p.form-des-text 地球村愛學習行動條碼掃描器，並掃描此行動條碼。
            //- 透過手機號碼登入
            button.button.form-group-button-1.w-100(
              @click="$root.goPhoneLogin()"
            )
              span 透過手機號碼登入

      //- large-時出現
      .col-lg-6.text-align-right.d-lg-block.d-none
        //- 返回按鈕
        .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
          @click="$root.goHome()"
        )
          span 返回首頁
          img.back-img(src="@/assets/image/btn-back@3x.png")
        //- 大圖
        img.login-img-lg(src="@/assets/image/login-img.png")
        //- 連結群
        ConnetGroup

      //- large以外時出現
      .col-md-12.d-block.d-lg-none
        //- 大圖
        img.login-img-md(src="@/assets/image/login-img.png")
        //- 連結群
        ConnetGroup
</template>
<script>
// import $ from 'jquery'
import ConnetGroup from '@/common/connet-group'
export default {
  name: 'login-phone',
  components: {
    ConnetGroup
  },
  data () {
    return {
      country_code: '+886',
      cellphone: '',
      accessToken: '',
      // == 處理監測物件
      autoChangeToken: false,
      autoChangeStatus: false
    };
  },
  created () {
    // 執行首次獲取 Token
    this.getLoginAccessToken()
    // 每三分鐘執行一次更新 Token
    var vm = this
    this.autoChangeToken = setInterval(function () { vm.getLoginAccessToken() }, (1000 * 60));
    this.autoChangeStatus = setInterval(function () { vm.getAccessStatus() }, (5000));
    // 關閉載入畫面
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    // 獲取目前授權狀態
    getAccessStatus () {
      if (!this.$root.loginStatus) {
        this.$root.system_ready = false
        this.$root.getTokenInfo(true)
      }
    },
    // 獲取授權用 Token
    getLoginAccessToken () {
      this.$http.get('member/signin/qrcode').then(function (_response) {
        if (_response.body.code === '0000') {
          this.accessToken = _response.body.data
          setTimeout(() => { this.$forceUpdate() }, 200)
        }
      })
    }
  },
  destroyed () {
    clearInterval(this.autoChangeToken)
    clearInterval(this.autoChangeStatus)
    console.log('--> 關閉監測物件...')
  }
}
</script>
<style lang="sass">
</style>
