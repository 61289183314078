<template lang="pug">
//- language-switch
.language-switch
  .btn-width-auto.switch-item.w-100(@click="switchLanguage('3kZzRl0qjQ')")
    img.lan-switch-img.lan-img-out(src="@/assets/image/lan-en.png")
    img.lan-switch-img.lan-img-on(src="@/assets/image/lan-en-w.png")
    span 美語頻道
  .btn-width-auto.switch-item.w-100(@click="switchLanguage('br9VNAzB1w')")
    img.lan-switch-img.lan-img-out(src="@/assets/image/lan-jp.png")
    img.lan-switch-img.lan-img-on(src="@/assets/image/lan-jp-w.png")
    span 日語頻道
  .btn-width-auto.switch-item.w-100(@click="switchLanguage('lPnVA1VpEj')")
    img.lan-switch-img.lan-img-out(src="@/assets/image/lan-ko.png")
    img.lan-switch-img.lan-img-on(src="@/assets/image/lan-ko-w.png")
    span 韓語頻道
</template>
<script>
export default {
  name: 'language-switch',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    switchLanguage (_lan) {
      if (this.$root.language_status !== _lan) {
        this.$root.useLoading()
        setTimeout(() => {
          this.$root.goHome()
        }, 400)
      }
      setTimeout(() => {
        this.$root.language_status = _lan
        this.$root.switch_bar_status = false
      }, 100);
    }
  }
}
</script>
