<template lang="pug">
//- 次列表頁
.home-index.page-index
  homeHeader
  section.article-list
    .container-fluid.home-main-title
      .row.align-items-center
        //- title
        .col-auto.text-align-left.flex-grow-1
          .title-content {{ tab.name }}
        //- 篩選按鈕
        //- .col-auto.text-align-right.flex-shrink-0
          button.button.btn-op-default.btn-sm.btn-width-auto.padding-none
            img.filter-img(src="@/assets/image/filter.png")

    //- 次列表tab(月刊類別)
    .container-fluid(v-if="tab.id === 'magazines'")
      .second-tab.text-align-left.row
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          v-for="(magazin, subIndex) in magazines[$root.language_status]",
          v-bind:class="magazin.click_statu ? 'btn-active' : 'btn-noactive'",
          @click="clickMore('magazines', subIndex)"
        ) {{ magazin.name }}
      .second-tab.text-align-left.row
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          v-for="(mon_period, subIndex) in mon_period_data",
          v-bind:class="mon_period.click_statu ? 'btn-active' : 'btn-noactive'",
          @click="clickMore('mon_period', subIndex)"
        ) {{ mon_period.text }}
      .second-tab.text-align-left.row
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          v-for="(mon_month, subIndex) in mon_month_data",
          v-bind:class="mon_month.click_statu ? 'btn-active' : 'btn-noactive'",
          @click="clickMore('mon_month', subIndex)"
        ) {{ mon_month.text }}

    //- 次列表tab(一般類別)
    .container-fluid(v-else)
      .second-tab.text-align-left.row
        a.cursor-pointe.tab-btn.text-align-center.cant-select(
          v-for="(sub, subIndex) in tab.sub",
          v-bind:class="sub.click_statu ? 'btn-active' : 'btn-noactive'",
          @click="clickMore(sub.id, subIndex)"
        ) {{ sub.name }}

    //- 次列表
    .container-fluid.text-align-center
      .home-listcontent.row.align-items-stretch
        ArticleItem.home-list-item.cursor-pointer.col-lg-2.col-md-3.col-sm-4.col-6(
          v-for="item in articles",
          :item="item",
          :list="false"
        )

      //- 載入更多文章
      button.text-align-center.button.btn-default.btn-md.see-more-btn(
        @click="getMoreArticles()"
      ) 載入更多文章
  GoTopButton
  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import homeArticleList from '@/components/home-mainlist'
import imgKnowHow from '@/assets/images/b-know-how-img-01.jpg'
import ArticleItem from '@/common/article-item'
import GoTopButton from '@/common/go-top-button'
import Footer from '@/components/home-footer'
export default {
  name: 'Index',
  components: {
    homeHeader,
    homeArticleList,
    imgKnowHow,
    ArticleItem,
    GoTopButton,
    Footer,
  },
  data () {
    return {
      tab: {},
      articles: [],
      categoryId: '',
      // 月刊清單
      magazines: {
        // 美語
        '3kZzRl0qjQ': [
          {
            id: 'l6V4Wn5zRP',
            name: '時尚美語',
            click_statu: false
          },
          {
            id: 'Dx35ExYkJG',
            name: '生活美語',
            click_statu: false
          }
        ],
        // 日語
        'br9VNAzB1w': [
          {
            id: '9bkYO2vzlV',
            name: '中級日語',
            click_statu: false
          }, {
            id: 'Qno4p1vyNa',
            name: '高級日語',
            click_statu: false
          }
        ],
        // 韓語
        'lPnVA1VpEj': [
        ],
      },
      mon_period_data: [
        {
          id: 'all',
          text: '全部',
          click_statu: true
        },
        {
          id: 2022,
          text: '2022',
          click_statu: false
        },
        {
          id: 2021,
          text: '2021',
          click_statu: false
        },
        {
          id: 2020,
          text: '2020',
          click_statu: false
        },
        {
          id: 2019,
          text: '2019',
          click_statu: false
        },
        {
          id: 2018,
          text: '2018',
          click_statu: false
        },
        {
          id: 2017,
          text: '2017',
          click_statu: false
        },
        {
          id: 2016,
          text: '2016',
          click_statu: false
        },
        {
          id: 2015,
          text: '2015',
          click_statu: false
        },
        {
          id: 2014,
          text: '2014',
          click_statu: false
        },
      ],
      mon_month_data: [
        {
          id: 'all',
          text: '全部',
          click_statu: true
        },
        {
          id: 1,
          text: '1月',
          click_statu: false
        },
        {
          id: 2,
          text: '2月',
          click_statu: false
        },
        {
          id: 3,
          text: '3月',
          click_statu: false
        },
        {
          id: 4,
          text: '4月',
          click_statu: false
        },
        {
          id: 5,
          text: '5月',
          click_statu: false
        },
        {
          id: 6,
          text: '6月',
          click_statu: false
        },
        {
          id: 7,
          text: '7月',
          click_statu: false
        },
        {
          id: 8,
          text: '8月',
          click_statu: false
        },
        {
          id: 9,
          text: '9月',
          click_statu: false
        },
        {
          id: 10,
          text: '10月',
          click_statu: false
        },
        {
          id: 11,
          text: '11月',
          click_statu: false
        },
        {
          id: 12,
          text: '12月',
          click_statu: false
        }
      ],
      // 搜索文章條件
      searchCondition: {
        tab: 0,
        option: {
          sub_type: [],
          year: [],
          month: []
        },
        filter: {
          attribute: 0,
          length: 0,
          content: 0,
          rank: ''
        },
        languageId: ''
      },
      page: 1,
      // 搜尋狀態
      searchStatus: false
    };
  },
  mounted () {
  },
  watch: {
    '$root.article_ready' (_status) {
      if (_status) {
        this.checkSystem()
      }
    },
  },
  async created () {
    this.categoryId = this.$route.params.categoryId
    this.checkSystem()
  },
  computed: {
  },
  methods: {
    // 確認系統狀況
    async checkSystem () {
      if (!this.$root.article_ready) {
        return
      }
      // 載入文章資料
      await this.getCurrentTab()
      await this.getArticles()
      // 關閉載入中
      this.$root.closeLoading()
    },
    // 獲取目前的 Tab 資料
    async getCurrentTab () {
      this.tab = {}
      var vm = this
      this.$root.tabs.forEach(async function (_tab) {
        if (_tab.id === vm.categoryId) {
          vm.tab = _tab
        }
      })
    },
    // 獲取目前的 Articles 資料
    async getArticles () {
      this.clickMore(this.tab.id, 0)
    },
    /** 
     * tabIndex 所屬分頁 Index
     * categoryIndex 所屬子類 Index
     */
    clickMore (_categoryId = '', _subIndex = 0) {
      var vm = this
      console.log('--> 本次處理類別: ', _categoryId)
      // 處理分頁 Tab
      this.searchCondition.tab = 3
      // 設置搜索條件的語言
      this.searchCondition.languageId = this.$root.language_status
      // 設置搜索條件的分頁
      this.searchCondition.categoryId = this.tab.id
      // 重置本頁頁數
      this.page = 1
      this.searchCondition.page = this.page
      // 處理月刊種類條件
      if (_categoryId === 'magazines') {
        this.magazines[this.$root.language_status].forEach(function (_category, _categoryIndex) {
          vm.magazines[vm.$root.language_status][_categoryIndex].click_statu = false
        })
        this.magazines[this.$root.language_status][_subIndex].click_statu = true

        // 處理搜索條件
        this.searchCondition.tab = 2
        this.searchCondition.categoryId = 'magazines'

        // 處理 sub_type
        let sub_type = []
        let is_all = false
        if (this.magazines[this.$root.language_status][0].click_statu && this.magazines[this.$root.language_status][0].id === 'all') {
          is_all = true
        }
        this.magazines[this.$root.language_status].forEach(function (_category) {
          if (!is_all) {
            if (_category.click_statu) {
              sub_type.push(_category.id)
            }
          }
        })
        this.searchCondition.option.sub_type = sub_type
        // 請求搜索文章
        this.filteredArticles()
        return
      }

      // 處理月刊年份條件
      else if (_categoryId === 'mon_period') {
        // 處理搜索條件
        this.searchCondition.tab = 2
        this.searchCondition.categoryId = 'magazines'


        this.mon_period_data.forEach(function (_category, _categoryIndex) {
          vm.mon_period_data[_categoryIndex].click_statu = false
        })
        vm.mon_period_data[_subIndex].click_statu = true


        // 處理 year
        let year = []
        let is_all = this.mon_period_data[0].click_statu
        this.mon_period_data.forEach(function (_category) {
          if (!is_all) {
            if (_category.click_statu) {
              year.push(_category.id)
            }
          }
        })
        this.searchCondition.option.year = year

        // 請求搜索文章
        this.filteredArticles()
        return
      }

      // 處理月刊月份條件
      else if (_categoryId === 'mon_month') {
        // 處理搜索條件
        this.searchCondition.tab = 2
        this.searchCondition.categoryId = 'magazines'

        this.mon_month_data.forEach(function (_category, _categoryIndex) {
          vm.mon_month_data[_categoryIndex].click_statu = false
        })
        vm.mon_month_data[_subIndex].click_statu = true


        // 處理 month
        let month = []
        let is_all = this.mon_month_data[0].click_statu
        this.mon_month_data.forEach(function (_category) {
          if (!is_all) {
            if (_category.click_statu) {
              month.push(_category.id)
            }
          }
        })
        this.searchCondition.option.month = month
        // 請求搜索文章
        this.filteredArticles()
        return
      }

      // else if (_categoryId === 'news') {}

      // 處理 Demo 條件
      else if (_categoryId === 'demo') {
        this.searchCondition.option.sub_type = []
        // 請求搜索文章
        this.filteredArticles()
      }

      //- 其他頁籤的情況
      else {
        vm.tab.sub.forEach(function (_category, _categoryIndex) {
          vm.tab.sub[_categoryIndex].click_statu = false
        })
        vm.tab.sub[_subIndex].click_statu = true

        // 處理 sub_type
        let sub_type = []
        let is_all = false
        if (vm.tab.sub[0].click_statu && vm.tab.sub[0].id === 'all') {
          is_all = true
        }
        vm.tab.sub.forEach(function (_category) {
          if (!is_all) {
            if (_category.click_statu) {
              sub_type.push(_category.id)
            }
          }
        })
        this.searchCondition.option.sub_type = sub_type
        // 請求搜索文章
        this.filteredArticles()
        return
      }
    },
    // 下一頁
    getMoreArticles () {
      this.page += 1
      this.searchCondition.page = this.page
      this.filteredArticles()
    },
    //- 請求搜索文章
    async filteredArticles () {
      // 印出搜索條件
      this.searchCondition.filter.attribute = this.$root.filters.type_status
      this.searchCondition.filter.length = this.$root.filters.type_time
      this.searchCondition.filter.content = this.$root.filters.type_content
      this.searchCondition.filter.rank = this.$root.filters.type_level
      console.log('--> 搜索條件: ' + JSON.stringify(this.searchCondition))

      // 開始搜索文章
      let vm = this
      this.$root.useLoading()
      this.searchStatus = true
      await this.$http.post('api/article/filtered/' + this.$root.language_status, this.searchCondition).then(async function (_response) {
        if (_response.body.code === '0000') {
          let articles = await this.$root.setArticlesImage(_response.body.data)
          if (vm.searchCondition.page > 1) {
            articles.forEach(function (_article) {
              vm.articles.push(_article)
            })
            console.log('  --> 檢查符合, 執行補入:', vm.articles)
          } else {
            vm.articles = articles
            console.log('  --> 檢查符合, 執行替換:', vm.articles)
          }
          // 關閉載入畫面
          vm.$root.closeLoading()
        }
        // 預防被鎖死
        setTimeout(() => { vm.$forceUpdate() }, 200)
        setTimeout(() => { vm.searchStatus = false }, 500)
      }, function () {
        // 預防被鎖死
        vm.$root.closeLoading()
        setTimeout(() => { vm.searchStatus = false }, 500)
      })
      vm.$root.list_ready = false
      setTimeout(() => {
        vm.$root.list_ready = true
      }, 500);
    },
  }
}
</script>