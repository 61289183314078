<template lang="pug">
//- login-phone
.login-view
  .container-fluid-sp
    .row
      .col-lg-6.col-md-12.text-align-right
        //- 返回按鈕
        .bcak-box.d-block.d-lg-none
          .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
            @click="$root.goHome()"
          )
            span 返回首頁
            img.back-img(src="@/assets/image/btn-back@3x.png")

        //- 登入表單
        .form.text-align-left
          .form-logo
            img(src="@/assets/image/head-logo.png")
          .from-title
            span 用戶登入
            .form-title-line
          .from-text
            p 歡迎回來
          .form-group
            //- 手機號碼input
            .form-group-title 手機號碼
            .phonecheck-input.form-group-input
              .from-img-text.float-left
                img.selectimg(
                  v-if="$root.country_code === '+886'",
                  src="@/assets/image/ic-tw@3x.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+1'",
                  src="@/assets/image/ic-usa.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+61'",
                  src="@/assets/image/ic-australia.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+64'",
                  src="@/assets/image/ic-nz.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+44'",
                  src="@/assets/image/ic-en.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+84'",
                  src="@/assets/image/ic-vietnam.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+86'",
                  src="@/assets/image/ic-ch.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+852'",
                  src="@/assets/image/ic-hk.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+853'",
                  src="@/assets/image/ic-macao.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+81'",
                  src="@/assets/image/ic-jp.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+82'",
                  src="@/assets/image/ic-kor.png"
                )
                img.selectimg(
                  v-if="$root.country_code === '+60'",
                  src="@/assets/image/ic-malaysia.png"
                )
                select.phoneselect.padding-none(v-model="$root.country_code")
                  option(
                    v-for="(code, index) in $root.country_codes",
                    :value="code"
                  ) {{ code }}

                .clearfix
              input.phoneinput.float-left(
                type="tel",
                placeholder="輸入您的手機號碼",
                v-model="cellphone",
                spellcheck="false"
              )

            //- 登入按鈕
            button.button.btn-default.form-group-button.w-100(
              @click="goVerCheck()"
            )
              span 登入
              img.form-group-button-backimg(
                src="@/assets/image/btn-next@3x.png"
              )

            //- 分隔線
            .form-group-divider-text
              .divider-line
              .divider-text 以其他方式登入

            //- 切換行動條碼登錄
            button.button.form-group-button-1.w-100(
              @click="$root.goScanLogin()"
            )
              span 透過行動條碼登入

            //- ps
            //- .from-ps-box
              span.from-ps-text 還沒有地球村美日韓語帳號？
              span.from-ps-button.cursor-pointer(@click="$root.goSignUp()") 建立帳號

      //- large-時出現
      .col-lg-6.text-align-right.d-lg-block.d-none
        //- 返回按鈕
        .btn-width-auto.button.btn-op-default.back-btn.text-align-right(
          @click="$root.goHome()"
        )
          span 返回首頁
          img.back-img(src="@/assets/image/btn-back@3x.png")
        //- 大圖
        img.login-img-lg(src="@/assets/image/login-img.png")
        //- 連結群
        ConnetGroup

      //- large以外時出現
      .col-md-12.d-block.d-lg-none
        //- 大圖
        img.login-img-md(src="@/assets/image/login-img.png")
        //- 連結群
        ConnetGroup

      #send-messenger-button
</template>
<script>
import $ from 'jquery'
import firebase from 'firebase'
import ConnetGroup from '@/common/connet-group'
export default {
  name: 'login-phone',
  components: {
    ConnetGroup
  },
  data () {
    return {
      cellphone: ''
    };
  },
  mounted () {
  },
  computed: {
  },
  created () {
    this.loadLogin()
    this.$root.closeLoading()
  },
  methods: {
    // 載入相關功能
    loadLogin () {
      console.log('====== 載入監測 phone input =======')
      // == 針對鍵盤聚焦進行特殊處理 ==
      $(document).on('focus', '.phoneinput', function () {
        $('.phonecheck-input').css({ 'box-shadow': '0 0 .2rem .1rem #5468ff44' })
      })
      // == 針對鍵盤失焦進行特殊處理 ==
      $(document).on('blur', '.phoneinput', function () {
        $('.phonecheck-input').css({ 'box-shadow': '0 2px 5px 0 rgba(126, 136, 149, 0.12)' })
      })
      // 使用 FireBase Phone Auth
      setTimeout(() => {
        console.log('--> 載入 FireBase Phone Auth...')
        firebase.auth().languageCode = 'zhTw'
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-messenger-button', {
          'size': 'invisible',
          'callback': function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit()
          }
        })
      }, 1500)
    },
    // 請求傳送驗證碼
    goVerCheck () {
      this.processWorking()
      // 組合電話號碼
      let phoneNumber = this.$root.common.getPhoneCode(this.country_code, this.cellphone)
      this.$root.cellphoneStep = phoneNumber
      console.log('等待驗證的電話號碼: ' + phoneNumber)
      // this.$root.putLog('等待驗證的電話號碼: ' + phoneNumber)
      // 請求發送驗證簡訊
      this.$root.sendPhoneNumberCode(phoneNumber)
    },
    // 處理中的相關操作
    processWorking (_time = 5000) {
      this.working = true
      setTimeout(() => { this.working = false }, _time);
    },
  }
}
</script>
<style lang="sass">
</style>
