<template lang="pug">
//- header
header.d-flex.home-header
  //- header 選單
  .container-fluid
    nav.nav-desktop.row.align-items-center.flex-nowrap
      .col.text-align-left.flex-shrink-0
        img.nav-logo.cursor-pointer.float-left(
          src="@/assets/image/head-logo.png",
          @click="$root.goHome()"
        )
        .clearfix

      .col-auto.flex-grow-0.flex-shrink-0.d-lg-block.d-none(
        v-for="nav in arrHeaderNav",
        v-if="!$root.hide_page_status"
      )
        a.cursor-pointer.nav-link(
          @click="navChange(nav.meta)",
          v-bind:class="$root.nav_meta === nav.meta ? 'font-weight-bold' : ''"
        ) {{ nav.title }}
          .under-line(v-if="$root.nav_meta === nav.meta")

      .col.text-align-right.flex-shrink-0.d-lg-block.d-md-block.d-none
        .lang-switch-btn.btn-width-auto.btn-op-default(
          v-if="!$root.hide_page_status",
          v-bind:class="$root.login_status ? 'header-lan-btm' : ''"
        )
          //- 美語頻道
          button.btn-width-auto.button.btn-op-default(
            v-if="$root.language_status === '3kZzRl0qjQ'",
            @click="openSwichBar()"
          )
            img.lan-img(src="@/assets/image/lan-en.png")
            span 美語頻道

          //- 日語頻道
          button.btn-width-auto.button.btn-op-default(
            v-if="$root.language_status === 'br9VNAzB1w'",
            @click="openSwichBar()"
          )
            img.lan-img(src="@/assets/image/lan-jp.png")
            span 日語頻道

          //- 韓語頻道
          button.btn-width-auto.button.btn-op-default(
            v-if="$root.language_status === 'lPnVA1VpEj'",
            @click="openSwichBar()"
          )
            img.lan-img(src="@/assets/image/lan-ko.png")
            span 韓語頻道
          //- 語言別選單
          LanguateSwitch(v-if="$root.switch_bar_status")
        //- 登入前
        .login-header.login-before(
          v-if="!$root.login_status",
          v-bind:class="!$root.hide_page_status ? 'header-btn-btm' : ''"
        )
          button.button.btn-default.btn-sm.btn-width-auto.header-btn(
            @click="$root.goSignUp()"
          )
            span 註冊
          button.button.btn-op-default.btn-sm.btn-width-auto.header-btn(
            @click="$root.goPhoneLogin()"
          )
            span 登入
        //- 登入後
        .login-header.login-after(
          v-else,
          v-bind:class="!$root.hide_page_status ? '' : 'header-adm-btm'"
        )
          button.btn-width-auto.button.btn-op-default.padding-none(
            @click="openHeadMenu()"
          )
            img.admin-img(v-if="$root.user.icon", :src="$root.user.icon")
            img.admin-img(v-else, src="@/assets/image/user.png")
          //- 個人選單
          HeadMenu(v-if="$root.head_menu_status")
</template>
<script>
import $ from 'jquery'
import LanguateSwitch from '@/common/language-switch'
import HeadMenu from '@/common/head-menu'
export default {
  name: 'homeHeader',
  components: {
    LanguateSwitch,
    HeadMenu
  },
  props: {},
  data () {
    return {
      arrHeaderNav: [
        {
          title: '數位影片',
          meta: 'home',
          status: true
        },
        /*
        {
          title: '觀看歷史',
          meta: 'history',
          status: false
        },
        {
          title: '訂閱方案',
          meta: 'program',
          status: false
        },
        */
        {
          title: '關於我們',
          meta: 'about',
          status: false
        }
      ],
      intScrollTop: 0 // 畫面滾動了多少
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    navChange (_meta) {
      this.$root.nav_meta = _meta
      this.$router.push({ name: _meta })
    },
    openSwichBar () {
      this.$root.switch_bar_status = !this.$root.switch_bar_status
      this.$root.head_menu_status = false
    },
    openHeadMenu () {
      this.$root.head_menu_status = !this.$root.head_menu_status
      this.$root.switch_bar_status = false
    },
    goTop () {
      $('html, body').animate({
        scrollTop: 0
      }, 600)
    }


  }
}
</script>
