<template lang="pug">
//- .program-item
.program-item
  .item-title.float-left 
    span {{ plan.name }}
    //- 原本的寫法
    //- span(v-if="sub.order") {{ sub.order.name }}
    //- span(v-else) {{ sub.plan.name }}
  .item-price.float-right
    span NT$ 900
    //- 原本的寫法
    //- span(v-if="sub.order") NT${{ $root.common.formatNumber(sub.order.price, 2) }}
    //- span(v-else) NT${{ $root.common.formatNumber(sub.plan.price, 2) }}
  .clearfix
  .item-group
    //- 訂閱中 (包含未過期、已過期)
    .item-ps(v-if="plan.status")
      //- 是否已過期
      .not-due(v-if="!plan.due_status")
        .ps-text 訂閱日期: {{ plan.effective_start }}
        //- 判斷是否為客服開通
        .ps-text
          span.ps-color(v-if="plan.order") 付款時間: {{ plan.payed_at }}
          span.ps-color(v-else) 客服開通: {{ plan.effective_start }}
        .ps-text 方案將於 {{ plan.effective_end }} 結束。

      .is-due(v-else)
        .ps-text 方案已於 {{ plan.effective_end }} 結束。

    //- 處理 (未付款)
    .item-ps(v-else)
      .ps-text 您訂閱的方案
        span.ps-red 尚未付款
      .ps-text 請迅速在時間內付款完成

      button.button.btn-default.btn-sm.btn-width-auto.item-button
        span 重新付款
</template>
<script>
export default {
  props: ['plan'],
  name: 'programRecordItem',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
