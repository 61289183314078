<template lang="pug">
.guide-view
  //- 960
  .guide-wrapper.d-lg-none.d-md-none.d-sm-flex.d-none
    .guide-box-1
      img.guide-bg-img(src="@/assets/image/guide-bg.png")
      .guide-content.text-align-center
        .guide-title.row.align-items-center.flex-nowrap
          span.float-left.title-text 全新
          .float-left.logo-img
            img(src="@/assets/image/head-logo.png")
          .clearfix
        .guide-text-group
          .guide-text 每天打開30分鐘
          .guide-text 用影片輕鬆聽懂美語、日語、韓語
        img.guide-hand-img(src="@/assets/image/guide-hand.png")
        .guide-button-group
          button.button.btn-default.btn-sm.btn-width-auto.guide-button-1
            span 立即下載App
        //- .guide-button-group
          button.button.btn-op-default.btn-sm.btn-width-auto.guide-button-2(
            @click="closeGuide()"
          )
            span 我下載過了
  //- 700
  .guide-wrapper.d-lg-none.d-md-none.d-sm-none.d-flex
    .guide-box-2
      img.guide-bg-img(src="@/assets/image/guide-bg1.png")
      .guide-content.text-align-center
        .title-text 全新
        .logo-img
          img(src="@/assets/image/head-logo.png")
        .guide-text-group
          .guide-text 每天打開30分鐘
          .guide-text 用影片輕鬆聽懂美語、日語、韓語
        img.guide-hand-img(src="@/assets/image/guide-hand1.png")
        .guide-button-group
          button.button.btn-default.btn-sm.btn-width-auto.guide-button-1
            span 立即下載App
        //- .guide-button-group
          button.button.btn-op-default.btn-sm.btn-width-auto.guide-button-2(
            @click="closeGuide()"
          )
            span 我下載過了
</template>
<script>
export default {
  name: 'Guide',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
      closeGuide () {
        this.$root.isGuide = false
      }
    };
  }
}
</script>
