<template lang="pug">
#app
  router-view
  //- loading畫面
  loadingView(:isVisible="$root.isLoading")
  //- 畫面縮小後partbar
  GuideView(v-if="$root.isGuide")
  //- alert
  AlertView(v-if="$root.isAlert")
</template>

<script>
import loadingView from '@/components/loading'
import GuideView from '@/components/guide'
import AlertView from '@/components/alert'
export default {
  name: 'App',
  components: {
    loadingView,
    GuideView,
    AlertView
  }
}
</script>
<style lang='sass'>
#app
  background: #ebeff3
</style>
