<template lang="pug">
//- B端 LOADING PAGE
transition(name="fade")
  .loading-wrapper(v-if="isVisible")
    .loading
      img.loading-player(src="@/assets/image/logo.png")
</template>
<script>
export default {
  name: 'Loading',
  props: {
    isVisible: {
      type: Boolean,
      default: true
    }
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
    };
  }
}
</script>
