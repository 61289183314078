<template lang="pug">
//- video-control
.video-control-box(v-if="article")
  .row.align-items-center.flex-nowrap
    //- 控制器-左
    .col.text-align-left.flex-shrink-0.padding-none
      //- 語言顯示
      .lan_now.control-text.float-left.cursor-pointer(@click="changeLanNow()")
        .lan_show(v-if="$root.language_status === '3kZzRl0qjQ'")
          span(v-if="$root.lan_show === 1") 中/英
          span(v-if="$root.lan_show === 2") 英
          span(v-if="$root.lan_show === 3") 中
        .lan_show(v-if="$root.language_status === 'br9VNAzB1w'")
          span(v-if="$root.lan_show === 1") 中/日
          span(v-if="$root.lan_show === 2") 日
          span(v-if="$root.lan_show === 3") 中
        .lan_show(v-if="$root.language_status === 'lPnVA1VpEj'")
          span(v-if="$root.lan_show === 1") 中/韓
          span(v-if="$root.lan_show === 2") 韓
          span(v-if="$root.lan_show === 3") 中

      //- 重覆播放
      .repeat_now.control-img-md.cursor-pointer.float-left(
        @click="changeRepeat()"
      )
        img(v-if="!$root.repeat_status", src="@/assets/image/repeat.png")
        img(v-else, src="@/assets/image/repeat@3x.png")

      //- 播放速度
      .speed_now.control-text.cursor-pointer.float-left(@click="changeSpeed()")
        span(v-if="$root.play_speed_status === 1") 正常
        span(v-if="$root.play_speed_status === 2") 快
        span(v-if="$root.play_speed_status === 3") 慢

      //- 文字大小
      .size_now.control-img-sm1.cursor-pointer.float-left(
        @click="changeSize()"
      )
        img(
          v-if="$root.size_show_status === 1",
          src="@/assets/image/text@3x.png"
        )
        img(
          v-if="$root.size_show_status === 2",
          src="@/assets/image/big@3x.png"
        )
        img(
          v-if="$root.size_show_status === 3",
          src="@/assets/image/small@3x.png"
        )

    //- 控制器-中
    .col-auto.flex-grow-0.flex-shrink-0.padding-none
      //- 後退
      //- img.control-img-sm.cursor-pointer.float-left(
      //-   src="@/assets/image/back@3x.png"
      //- )
      //- 播放
      img.control-img-lg.cursor-pointer.float-left(
        @click="changePlayStatus()",
        v-if="!$root.play_status",
        src="@/assets/image/play@3x.png"
      )
      //- 暫停
      img.control-img-lg.cursor-pointer.float-left(
        @click="changePlayStatus()",
        v-if="$root.play_status",
        src="@/assets/image/stop@3x.png"
      )
      //- 前進
      //- img.control-img-sm.cursor-pointer.float-left(
      //-   src="@/assets/image/next@3x.png"
      //- )

    //- 控制器-右
    .col.text-align-right.flex-shrink-0.padding-none
      //- img.control-img-sm.cursor-pointer.float-right(
      //-   src="@/assets/image/zoom.png"
      //- )
      .control-text.cursor-pointer.float-right(
        v-if="article.swich_commentary_status",
        @click="changeSwicth(true)",
        v-bind:class="!$root.article_switch ? 'control-text-gray' : 'control-text-green'"
      ) 講解
      .control-text.cursor-pointer.float-right(
        v-if="article.swich_readaloud_status",
        @click="changeSwicth(false)",
        v-bind:class="$root.article_switch ? 'control-text-gray' : 'control-text-green'"
      ) 朗讀
</template>
<script>
export default {
  name: 'video-control',
  props: ['article'],
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    //- 切換語言顯示
    changeLanNow () {
      if (this.$root.$data.lan_show === 1) {
        this.$root.$data.lan_show = 2
        return false
      }
      if (this.$root.$data.lan_show === 2) {
        this.$root.$data.lan_show = 3
        return false
      }
      if (this.$root.$data.lan_show === 3) {
        this.$root.$data.lan_show = 1
        return false
      }
    },
    //- 切換文字大小
    changeSize () {
      if (this.$root.$data.size_show_status === 1) {
        this.$root.$data.size_show_status = 2
        return false
      }
      if (this.$root.$data.size_show_status === 2) {
        this.$root.$data.size_show_status = 3
        return false
      }
      if (this.$root.$data.size_show_status === 3) {
        this.$root.$data.size_show_status = 1
        return false
      }
    },
    //- 切換重覆播放
    changeRepeat () {
      this.$root.$data.repeat_status = !this.$root.$data.repeat_status
    },

    //- 切換播放/暫停
    changePlayStatus () {
      this.$root.$data.play_status = !this.$root.$data.play_status
    },
    //- 切換播放速度
    changeSpeed () {
      if (this.$root.play_speed_status === 1) {
        this.$root.play_speed_status = 2
        return false
      }
      if (this.$root.play_speed_status === 2) {
        this.$root.play_speed_status = 3
        return false
      }
      if (this.$root.play_speed_status === 3) {
        this.$root.play_speed_status = 1
        return false
      }
    },

    //- 切換
    changeSwicth (_status) {
      this.$root.article_switch = _status
    }
  }
}
</script>
