<template lang="pug">
//- 首頁
.home-index.page-index
  homeHeader
  //- Demo 文章
  homeArticleList(
    :list="{ id: 'demo', name: 'Demo', articles: $root.articleList.demo }"
  )
  //- 最新文章
  homeArticleList(
    :list="{ id: 'new', name: '最新', articles: $root.articleList.news }"
  )
  //- 月刊文章
  homeArticleList(
    :list="{ id: 'magazines', name: '月刊', articles: $root.articleList.magazines }"
  )
  //- 其他類別文章
  homeArticleList(v-for="tab in $root.articleList.categories", :list="tab")
  GoTopButton
  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import homeArticleList from '@/components/home-mainlist'
import GoTopButton from '@/common/go-top-button'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    homeArticleList,
    GoTopButton
  },
  data () {
    return {
    };
  },
  async created () {
    await this.$root.checkArticles()
    this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
