<template lang="pug">
//- head-menu
.head-menu
  .head-menu-top
    img.menu-admin-img.float-left(
      v-if="$root.user.icon",
      :src="$root.user.icon"
    )
    img.menu-admin-img.float-left(v-else, src="@/assets/image/user.png")
    .menu-top-box.float-left
      .admin-title.no-down {{ $root.user.name }}
      .admin-ps 歡迎你
      .admin-edit.cursor-pointer(@click="$root.goProfile(1)") 編輯個人資料
    .clearfix
  .head-menu-mid
    .menu-mid-item
      .admin-pro.float-left 點數
      .admin-pro.float-right 0點
      .clearfix
    .menu-mid-item
      .admin-pro.float-left 方案到期日：
      .admin-pro.float-right
        span(v-if="$root.user.last_plan") {{ $root.user.last_plan }}
        span(v-else) 免費會員
      .clearfix
  //- .head-menu-btm
    .menu-btm-item.cursor-pointer(@click="$root.goCollect()")
      img.btm-item-img.float-left(src="@/assets/image/favorite.png")
      .btm-item-text.float-left 收藏
      .clearfix
    .menu-btm-item.cursor-pointer(@click="$root.goProfile(2)")
      img.btm-item-img.float-left(src="@/assets/image/gift.png")
      .btm-item-text.float-left 活動碼
      .clearfix
    .menu-btm-item.cursor-pointer(@click="$root.goProfile(3)")
      img.btm-item-img.float-left(src="@/assets/image/program.png")
      .btm-item-text.float-left 方案紀錄
      .clearfix
  .head-menu-btm
    .menu-btm-item.cursor-pointer(@click="logOut()")
      img.btm-item-img.float-left(src="@/assets/image/logout.png")
      .btm-item-text.float-left 登出
      .clearfix
</template>
<script>
export default {
  name: 'head-menu',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    async logOut () {
      // 設定登出
      this.$root.useLoading()
      this.$http.delete('api/member/logout').then(async function (_response) {
        this.$root.closeLoading()
        if (_response.body.code === '0000') {
          // 清除資料
          await this.$root.resetStorageData()
          // 重新確認 Token 狀態
          await this.$root.getToken()
          // 前往首頁
          await this.$root.goHome()
        }
      }, function (_error) {
        this.$root.useLoading()
      })
    }
  }
}
</script>
