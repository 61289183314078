<template lang="pug">
//- article-list
section.article-list(v-if="list.articles && list.articles.length > 0")
  .container-fluid.home-main-title
    .title-content.row.align-items-center.flex-nowrap
      .col-auto.text-align-left.flex-grow-1 {{ list.name }}
      button.col-auto.text-align-right.sub-more.flex-grow-0.button.btn-op-default.btn-sm.btn-width-auto.color-sub-title(
        @click="goHomeSecond(list.id)"
      ) 看更多
  .container-fluid
    .home-listcontent.row.align-items-stretch
      ArticleItem.home-list-item.cursor-pointer.col-lg-2.col-md-3.col-sm-4.col-6(
        v-for="article in list.articles.slice(0, 12)",
        :item="article",
        :list="list"
      )
</template>
<script>
import ArticleItem from '@/common/article-item'
export default {
  name: 'articleMainList',
  components: {
    ArticleItem
  },
  props: ['list'],
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    goHomeSecond (in_class) {
      this.$router.push({ name: 'home-second', params: { categoryId: in_class } })
      this.$root.second_list_class = in_class
    }
  }
}
</script>
