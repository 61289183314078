<template lang="pug">
//- article-top
.article-top(v-if="article.categoriesObj")
  //- 文章類別
  .article-class
    .float-left.font-weight-bold.article-top-text.flex1(
      v-if="article.categoriesObj.length > 0"
    ) {{ article.categoriesObj[0].name }}
    .float-left(v-if="article.rank")
      span.card-top-level1.article-top-level(v-if="article.rank.name === '初級'") {{ article.rank.name }}
      span.card-top-level2.article-top-level(v-if="article.rank.name === '中級'") {{ article.rank.name }}
      span.card-top-level3.article-top-level(v-if="article.rank.name === '高級'") {{ article.rank.name }}
      span.card-top-level3.article-top-level(v-if="article.rank.name === '優級'") {{ article.rank.name }}
      span.card-top-level3.article-top-level(v-if="article.is_demo") demo
    .clearfix
  //- 文章title
  .article-title {{ article.title }}
  .article-ch-title.float-left {{ article.title_zh }}
  .article-other.float-right
    //- 文章收藏數
    //- .other-item.float-left
      img.otherimg.float-left.cursor-pointer(src="@/assets/image/star@3x.png")
      //- img.otherimg.float-left.cursor-pointer(
      //-   src="@/assets/image/star-empty@3x.png"
      //- )
      span.othertext.float-left {{ article.likes }}
      .clearfix
    //- 文章閱讀數
    //- .other-item.float-left
      img.otherimg1.float-left(src="@/assets/image/el-ear.png")
      span.othertext.float-left {{ article.views }}
      .clearfix
    //- 文章分享按鈕
    //- .other-item.float-left
      img.otherimg2.cursor-pointer(src="@/assets/image/upload-down@3x.png")
    .clearfix
  .clearfix
</template>
<script>
export default {
  name: 'article-top',
  props: ['article'],
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
