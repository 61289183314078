<template lang="pug">
//- B端 FOOTER
footer.home-footer
  .foot-top-hr
  //- 主要內容，分左右兩塊
  .container-fluid
    .row
      //- 左邊，LOGO 和社群
      .col-lg-4.col-md-12.mb-10
        a
          img.footer-logo(src="@/assets/image/head-logo.png")
        ul.footer-system-list.d-flex.align-items-center
          li
            a.middle-center
              img(src="@/assets/image/appstore.png")
          li
            a.middle-center
              img(src="@/assets/image/googleplay.png")
      //- 右邊，一堆選單
      .col-md-2.col-sm-4.col-xs-12.mb-10
        .footer-title 關於
        .footer-list-content(v-for="item in arrList")
          a.footer-list-item {{ item.name }}
      .col-md-2.col-sm-4.col-xs-12.mb-10
        .footer-title 語言頻道
        .footer-list-content(v-for="lan in lanList")
          a.footer-list-item(@click="switchLanguage(lan.lan)") {{ lan.name }}
      .col-md-2.col-sm-4.col-xs-12.mb-10
        .footer-title 支援服務
        .footer-list-content(v-for="sup in supList")
          a.footer-list-item(@click="footChange(sup.meta)") {{ sup.name }}

  //- copy right
  .container-fluid.text-align-center
    .address
      span © 2019 地球村愛學習. All rights reserved.Privacy Policy
</template>
<script>
export default {
  name: 'homeFooter',
  components: {

  },
  data () {
    return {
      arrList: [
        {
          name: '認識地球村愛學習'
        },
        {
          name: '常見問題'
        },
        {
          name: '聯絡我們'
        },
      ],
      lanList: [
        {
          name: '美語頻道',
          lan: '3kZzRl0qjQ'
        },
        {
          name: '日語頻道',
          lan: 'br9VNAzB1w'
        },
        {
          name: '韓語頻道',
          lan: 'lPnVA1VpEj'
        },
      ],
      supList: [
        {
          name: '隱私政策',
          meta: 1
        },
        {
          name: '使用條款',
          meta: 2
        },
        {
          name: '消費協議',
          meta: 3
        }
      ],
    };
  },
  methods: {
    switchLanguage (_lan) {
      if (this.$root.language_status !== _lan) {
        this.$root.useLoading()
        setTimeout(() => {
          this.$root.closeLoading()
          this.$root.goHome()
        }, 400)
      }
      setTimeout(() => {
        this.$root.language_status = _lan
        this.$root.switch_bar_status = false
      }, 100);
    },
    footChange (_meta) {
      this.$root.goRule(_meta)
    }
  }
}
</script>
