<template lang="pug">
//- EditProfile 
.container-fluid-xs
  .profile-box
    .profile-photo-box
      img.profile-photo(src="@/assets/image/user.png")
      //- img.profile-camera.cursor-pointer(src="@/assets/image/camera.png")
    .form
      .form-group
        //- 姓名input
        .form-input-group
          .form-group-title 會員姓名
          input.profile-form-group-input(
            type="text",
            placeholder="輸入您的姓名",
            v-model="$root.user.name"
          )
        //- 手機號碼input
        .form-group-title 手機號碼
        .form-input-group
          .profile-phonecheck-input.profile-form-group-input-phone
            //- .from-img-text.float-left
              img.selectimg(
                v-if="$root.country_code === '+886'",
                src="@/assets/image/ic-tw@3x.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+1'",
                src="@/assets/image/ic-usa.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+61'",
                src="@/assets/image/ic-australia.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+64'",
                src="@/assets/image/ic-nz.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+44'",
                src="@/assets/image/ic-en.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+84'",
                src="@/assets/image/ic-vietnam.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+86'",
                src="@/assets/image/ic-ch.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+852'",
                src="@/assets/image/ic-hk.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+853'",
                src="@/assets/image/ic-macao.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+81'",
                src="@/assets/image/ic-jp.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+82'",
                src="@/assets/image/ic-kor.png"
              )
              img.selectimg(
                v-if="$root.country_code === '+60'",
                src="@/assets/image/ic-malaysia.png"
              )
              .phoneselect.padding-none
                span {{ $root.country_code }}
              .clearfix
            .phoneinput.float-left
              span +{{ $root.user.phone }}

        //- 信箱input
        .form-input-group
          .form-group-title 信箱
          input.profile-form-group-input(
            type="text",
            placeholder="輸入您的信箱",
            v-model="$root.user.email"
          )

        //- 性別select
        .form-input-group
          .form-group-title 性別
          select.profile-form-group-input(v-model="$root.user.sex")
            option 選擇您的性別
            option(value="1") 男性
            option(value="2") 女性
          img.select-drop-down-img(src="@/assets/image/ic-arrown-down@3x.png")

        //- 所在區select
        .form-input-group
          .form-group-title 所在區
          select.profile-form-group-input(v-model="$root.user.country")
            option 選擇您的所在區
            option(v-for="(city, index) in citys", :value="city.id") {{ city.city }}
          img.select-drop-down-img(src="@/assets/image/ic-arrown-down@3x.png")

        //- 儲存按鈕
        button.button.btn-default.form-group-button.w-100
          span 儲存
</template>
<script>
export default {
  name: 'EditProfile',
  props: {
  },
  components: {
  },
  data () {
    return {
      cellphone: '',
      citys: [
        {
          "id": 1,
          "city": "臺北市"
        },
        {
          "id": 2,
          "city": "新北市"
        },
        {
          "id": 3,
          "city": "桃園市"
        },
        {
          "id": 4,
          "city": "臺中市"
        },
        {
          "id": 5,
          "city": "臺南市"
        },
        {
          "id": 6,
          "city": "高雄市"
        },
        {
          "id": 7,
          "city": "新竹縣"
        },
        {
          "id": 8,
          "city": "苗栗縣"
        },
        {
          "id": 9,
          "city": "彰化縣"
        },
        {
          "id": 10,
          "city": "南投縣"
        },
        {
          "id": 11,
          "city": "雲林縣"
        },
        {
          "id": 12,
          "city": "嘉義縣"
        },
        {
          "id": 13,
          "city": "屏東縣"
        },
        {
          "id": 14,
          "city": "宜蘭縣"
        },
        {
          "id": 15,
          "city": "花蓮縣"
        },
        {
          "id": 16,
          "city": "臺東縣"
        },
        {
          "id": 17,
          "city": "澎湖縣"
        },
        {
          "id": 18,
          "city": "金門縣"
        },
        {
          "id": 19,
          "city": "連江縣"
        },
        {
          "id": 20,
          "city": "基隆市"
        },
        {
          "id": 21,
          "city": "新竹市"
        },
        {
          "id": 22,
          "city": "嘉義市"
        },
        {
          "id": 23,
          "city": "其他地方"
        }
      ]
    };
  },
  computed: {

  },
  created () {
  },
}
</script>
