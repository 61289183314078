<template lang="pug">
//- ActivityCode 
.container-fluid-xs
  .activety-all
    img.gift-img(src="@/assets/image/gift-code.png")
    //- form
    .activity-box
      .activity-text 輸入活動碼可獲得會員期限、點數、部份文章閱讀
      input.activity-input(type="text", placeholder="輸入活動碼")
      //- 確認按鈕
      button.button.btn-default.form-group-button.w-100
        span 確認
    //- 點數數字
    .activity-num-box
      .activity-now-num 1,000點
      .activity-now-text 現有點數
    //- 點數紀錄表格
    .activity-table
      .table-title 點數紀錄
      table.table-normal
        thead
          tr
            th 日期
            th 獲得來源
            th 獲得點數
            th 到期日
            th 點數餘額
        tbody(v-if="activity_list.length < 1")
          tr
            td(colspan="5") 目前沒有點數紀錄
        tbody(v-else)
          //- 僅顯示前10筆
          tr(v-if="!show_more", v-for="act in activity_list.slice(0, 10)")
            td {{ act.start_date }}
            td {{ act.from }}
            td {{ act.num }}
            td {{ act.end_date }}
            td {{ act.total_num }}
          //- 顯示全部
          tr(v-if="show_more", v-for="act in activity_list")
            td {{ act.start_date }}
            td {{ act.from }}
            td {{ act.num }}
            td {{ act.end_date }}
            td {{ act.total_num }}

      .activity-button-group(v-if="!show_more && activity_list.length > 10")
        button.button.btn-default.btn-sm.btn-width-auto.activity-button(
          @click="showMore()"
        )
          span 顯示前 3 個月紀錄
</template>
<script>
export default {
  name: 'ActivityCode',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
      show_more: false,
      activity_list: [
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        },
        {
          start_date: '2020-12-05',
          end_date: '2020-12-31',
          from: '月刊活動碼',
          num: '+5',
          total_num: '1,000'
        }
      ]
    };
  },
  methods: {
    showMore () {
      this.show_more = true
    }
  }
}
</script>
