
// ---- SASS
import '@/sass/reset.sass'
import '@/sass/ui.sass'
import '@/sass/grid.sass'
import '@/sass/layout.sass'
import '@/sass/plugin.sass'

import '@lottiefiles/lottie-player'
import 'font-awesome/css/font-awesome.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'

import VueQrcode from '@xkeshi/vue-qrcode'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
import VueLocalStorage from 'vue-localstorage'
import VueVideoPlayer from 'vue-video-player'
import 'videojs-contrib-hls'

// 載入 Library
import Common from './library/Common'

// Import FireBase
import firebase from 'firebase/app'

// require videojs style
import 'video.js/dist/video-js.css'
// import imgKnowHow from '@/assets/images/b-know-how-img-01.jpg'

Vue.config.productionTip = false
Vue.use(VueResource)
Vue.use(VueCookies)
Vue.use(VueLocalStorage)
Vue.component(VueQrcode.name, VueQrcode)
Vue.use(VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */)


const configOptions = {
  apiKey: "AIzaSyBtjzm4l61fy-6_aJWOJbR6Va6ejSzKXsk",
  authDomain: "gvo-videoapp.firebaseapp.com",
  databaseURL: "https://gvo-videoapp.firebaseio.com",
  projectId: "gvo-videoapp",
  storageBucket: "gvo-videoapp.appspot.com",
  messagingSenderId: "33377823245",
  appId: "1:33377823245:web:54bf5efa706f12ce854344",
  measurementId: "G-N5J841HMG6"
}

firebase.initializeApp(configOptions)

// ================
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push(function (_request, _next) {
  let _authToken = 'Bearer ' + Vue.localStorage.get('api-token')
  Vue.http.headers.common['DeviceToken'] = _authToken
  Vue.http.headers.common['Device-Id'] = Vue.localStorage.get('dev-id')
  Vue.http.headers.common['Content-Type'] = 'application/json'

  // 處理一般程序
  _next(_response => {
    var body = _response.body
    // console.log('執行錯誤檢查:' + body.code)
    // console.log('執行錯誤檢查:' + body)
    // 200 不會有的
    // 1078 token 失效，丟進 signin
    if (body) {
      switch (body.code) {
        case '0404':
          if (body.message === 'Device not found') {
            this.getNewToken()
            this.getToken()
          }
          break
        // case '1101':
        //   this.$router.push({ name: 'launchpage' })
        //   break
        // default:
        //   let common = new Common
        //   if (_response.body.message) {
        //     common.naviteAlert('發生問題', _response.body.message)
        //   }
        //   break
      }
    }
  })
})

// 當每一次跳轉頁面，都把頁面 loading 打開
router.afterEach(() => {
  /* 返回到最頂上 */
  // $('*').animate({ scrollTop: 0 }, 'slow');
})

new Vue({
  router,
  render: h => h(App),
  data: {
    nav_meta: 'home',
    hide_page_status: false,
    // == Token ==
    token: '',
    devId: '',
    // == 系統狀態 ==
    system_ready: false,
    padding_check: false,
    article_ready: false,
    //------- video app ------
    // == 媒體主機 ==
    mediaServer: process.env.VUE_APP_MEDIA_URL,
    // API 基礎網址
    base_url: process.env.VUE_APP_API_URL,
    // == 系統版本 ==
    system_version_type: process.env.VUE_APP_NODE,
    // == 登入狀態
    login_status: false,
    // == 遊客狀態
    guest_model: false,
    user: {
      "id": false,
      "phone": false,
      "name": false,
      "icon": false,
      "sex": false,
      "status": false,
      "point": false,
      "country": false,
      "last_login_at": false,
      "last_logout_at": false,
      "created_at": false,
      "updated_at": false,
      "deleted_at": false,
      "email": false,
      "invite_link": false,
      "referee": {},
      "invite_num": false,
      "invited_at": false,
      "notation": false,
      "registed_at": false,
      "last_plan": false
    },
    cellphoneStep: '',
    // == 篩選種類清單 ==
    filters: {
      type_status: 0,
      type_time: 0,
      type_content: 0,
      type_level: 0,
    },
    // == 個人資料畫面狀態
    profile_status: 1,
    // == 個人資料畫面狀態
    rule_status: 1,
    // == Loading狀態
    isLoading: false,
    // == 導引狀態
    isGuide: true,
    // == Alert狀態
    isAlert: false,
    alert_type: 1,
    alert: {
      title: '',
      text: ''
    },
    // == 目前語言
    language_status: '3kZzRl0qjQ',
    // == 切換語言視窗
    switch_bar_status: false,
    // == 個人選單part bar
    head_menu_status: false,
    // == 目前類別
    second_list_class: '國際新聞',
    tabs: [],
    // == 文章控制
    play_status: false,
    lan_show: 1,
    size_show_status: 1,
    play_speed_status: 1,
    repeat_status: false,
    currentArticle: false,
    article_id: false,
    //講解 閱讀
    article_switch: false,
    // == 國碼 ==
    country_codes: [
      '+1', '+61', '+64', '+44', '+84', '+86', '+886', '+852', '+853', '+81', '+82', '+60',
    ],
    country_code: '+886',
    specialTyeps: ['WlXZNl2OxN', 'MWxOWroy8n', 'YGPydaBO87'],
    articleList: {
      demo: [],
      news: [],
      magazines: [],
      categories: []
    }
  },
  mounted () {
    // 每次 router 更新，開都啟 loading
    this.$router.beforeEach((to, from, next) => {
      this.closeSwitchLanBar()
      this.closeHeadMenu()
      this.isLoading = true

      setTimeout(() => {
        next()
      }, 300)
    })
    // 每次 router 更新，都滾到頂端
    this.$router.afterEach(() => {
      $('html, body').scrollTop(0)
    })
  },
  created () {
    // 先開載入中
    this.useLoading()
    // 載入工具
    this.common = new Common(this)
    // 產生裝置序號
    this.createDeviceId()
    // 確認 Token 有效
    this.getToken()
  },
  watch: {
    async system_ready (_status) {
      if (!_status) {
        return
      }

      console.log('###################################');
      console.log('--> system ready ok...');
      console.log('');

      // 獲取語言設定
      let last_language = this.getData('use_language')
      if (last_language) {
        this.language_status = last_language
      }

      // 如果未登入，改用遊客資格
      if (!this.login_status && !this.guest_model) {
        await this.guestLogin()
      }

      this.checkArticles()
    },
    // 監測到變更語言
    language_status (_lang) {
      if (_lang) {
        console.log('--> 監測到語言被變更: ', _lang)
        this.setData('use_language', _lang)
        this.getArticles()
      }
    },
  },
  methods: {
    // ########################
    // ###  VideoAPP Tools
    // ########################
    createDeviceId () {
      let deviceId = this.getData('dev-id')
      if (!deviceId) {
        let uuid = this.common.createUUID()
        this.setData('dev-id', uuid)
      }
    },
    resetStorageData () {
      this.user = {}
      this.system_ready = false
      this.login_status = false
      this.accessToken = ''
    },
    //- 確認 Token 是否可用
    getToken () {
      // 獲取已暫存 Token
      let accessToken = this.getData('api-token')
      // 獲取 Token 狀態
      if (accessToken) {
        this.getTokenInfo()
      } else {
        this.getNewToken()
      }
    },
    //- 請求後端確認目前 Token 資訊
    async getTokenInfo (_onlyCheck = false) {
      await this.$http.get('device/token/info').then(async function (_response) {
        console.log('--> [OK] Token Info: ' + JSON.stringify(_response))
        // 完成動作
        this.login_status = _response.body.data.bind
        // 獲取已經登入的資料
        if (this.login_status) {
          await this.getUser(true)
        }
        // 設定系統可啟用
        if (!_onlyCheck || this.login_status) {
          this.useLoading()
          this.$data.system_ready = true
        }
      }, function (_response) {
        console.log('--> [Err] Token Info: ' + JSON.stringify(_response))
        this.getNewToken()
      })
    },
    //- 請求後端核發新的 Token
    async getNewToken () {
      let deviceType = 0
      deviceType = 2
      await this.$http.post('device/token/create', {
        device_id: this.getData('dev-id'),
        type: deviceType
      }).then(function (_response) {
        if (_response.body.code === '0000') {
          console.log('--> Token Info: ' + JSON.stringify(_response))
          // 寫入新的 Token 資料
          this.setData('api-token', _response.body.data.token)
          // 完成動作
          this.$data.system_ready = true
        }
      }, function (_response) {
        console.log('--> Token Info: ' + JSON.stringify(_response))
      })
    },
    //- 請求取得目前已經登入的資訊
    async getUser (_checkBind = false) {
      await this.$http.get('api/member').then(function (_response) {
        if (_response.body.code === '0000') {
          let user = _response.body.data
          // 驗證是否為遊客
          this.guest_model = false
          if (user.status === 2) {
            this.login_status = false
            this.guest_model = true
            // 處理會員資料
            user.name = '遊客'
          }
          // 驗證為一般會員
          if (user.status === 1) {
            this.login_status = true
            this.guest_model = false
            this.getPlanList()
          }
          // 回放會員資料
          this.user = user
          console.log('--> 獲取會員資料: ' + JSON.stringify(user))

          // 來源為掃碼驗證
          if (!_checkBind) {
            this.getArticles()
          }

          if (_checkBind && user.status === 1) {
            this.goHome()
          }

          setTimeout(() => { this.$forceUpdate() }, 200)
        }
      }, function () { })
    },
    // 獲取方案清單資料
    async getPlanList () {
      await this.$http.get('api/plan/list?event=1').then(async function (_response) {
        if (_response.body.code === '0000') {
          // 獲取原始方案資料
          let plans = _response.body.data.plans

          // 整理方案資料
          let setPlans = {}
          await plans.forEach(async function (_plan) {
            if (_plan.plannable.days > 31 && _plan.plannable.days <= 1095 && _plan.plannable.type <= 4) {
              _plan.canuse = true
              setPlans[_plan.paid_code] = _plan
            }
          })
        }
      }, function () {
        this.$root.closeLoading()
      })
    },
    //- 訪客登入
    async guestLogin () {
      this.useLoading()
      await this.$http.post('member/guest').then(function (_response) {
        if (_response.body.code === '0000') {
          // 啟用遊客登入模式
          this.guest_model = true
        }
      }, function () {
        this.closeLoading()
      })
    },
    //- 檢測無文章資料就重拿文章
    async checkArticles () {
      if (!this.system_ready) {
        this.padding_check = true
        return
      }

      // 不要每次都載入文章
      let allowGetArticle = ["home", "home-second", "article"]
      if (this.$root.articleList.categories.length === 0 && allowGetArticle.indexOf(this.$route.name) > -1) {
        await this.getArticles()
      } else {
        this.closeLoading()
      }
    },
    //- 獲取首頁文章
    async getArticles () {
      this.useLoading()
      let vm = this
      // 設置基礎類別清單
      this.tabs = [
        { id: 'demo', name: "Demo", sub: [] },
        { id: 'news', name: "最新", sub: [] },
        { id: 'magazines', name: "月刊", sub: [] },
      ]
      // 獲取首頁所有文章清單
      this.articleList = {
        categories: []
      }
      await this.$http.get('api/article/list/' + this.language_status, {
        params: {
          isWeb: false //true
        }
      }).then(async function (_response) {
        if (_response.body.code === '0000') {
          let articles = _response.body.data
          // 處理demo文章
          this.articleList.demo = await this.setArticlesImage(articles.demo)
          // 處理最新文章
          this.articleList.new = await this.setArticlesImage(articles.new)
          // 處理免費文章
          this.articleList.free = await this.setArticlesImage(articles.free)
          // 處理月刊文章
          this.articleList.magazines = await this.setArticlesImage(articles.magazines)
          // 處理分類文章
          this.articleList.categories = []
          articles.categories.forEach(async function (_categorie) {
            vm.tabs.push({ id: _categorie.id, name: _categorie.name, sub: [] })
            let row_articles = await vm.$root.setArticlesImage(_categorie.articles)
            vm.articleList.categories.push({ id: _categorie.id, name: _categorie.name, articles: row_articles })
          })
          // 請求文章類別清單
          await this.getArticleCategoryList()
          // 文章準備完成
          this.article_ready = true
        }
        this.closeLoading()
      }, function () {
        // 關閉載入中
        setTimeout(() => { this.closeLoading() }, 250);
      })
    },
    // 獲取類別清單
    getArticleCategoryList () {
      let vm = this
      this.$http.get('api/category', {
        params: {
          language: this.language_status
        }
      }).then(function (_response) {
        let categorys = _response.body.data
        vm.categorys_tab = _response.body.data
        // 逐一比對後端傳回的分類項目
        categorys.forEach(function (_category) {
          // 逐一比對前端已有的分類項目
          vm.tabs.forEach(function (_tab, _tabIndex) {
            if (_category.id === _tab.id) {
              if (_tab.name === '快訊') {
                vm.tabs[_tabIndex].sub = []
              } else {
                // 預設清空子類清單
                vm.tabs[_tabIndex].sub = [
                  { id: 'all', name: '全部', click_statu: true }
                ]
              }
              // 逐一將子類建入類別清單
              _category.subCategories.forEach(function (_row) {
                _row.click_statu = false
                vm.tabs[_tabIndex].sub.push(_row)
              })
            }
          })
        })
      })
    },
    // 處理文章資源網址
    async setArticlesImage (_articles) {
      let vm = this
      await _articles.forEach(async function (_article, _index) {
        // 處理圖片參數
        _articles[_index].image_url = await vm.common.getMediaPreviewUri(_article.image_id, true)
        // console.log('  -----> 處理圖片參數...')

        // 調整音頻文章的參數
        if (_article.type === 1) {
          // 處理朗讀參數
          if (_article.read_aloud_id) {
            _articles[_index].read_aloud_url = {
              url: await vm.common.getMediaPreviewUri(_article.read_aloud_id, true),
              ext: _article.read_aloud_ext
            }
            // console.log('  -----> 處理朗讀聲音參數...')
          }
          // 處理解說參數
          if (_article.commentary_id) {
            _articles[_index].commentary_url = {
              url: await vm.common.getMediaPreviewUri(_article.commentary_id, true),
              ext: _article.read_aloud_ext
            }
            // console.log('  -----> 處理解說聲音參數...')
          }
        }

        // 調整視頻文章的參數
        if (_article.type === 2) {
          // 處理朗讀參數
          if (_article.read_aloud_id) {
            _articles[_index].read_aloud_url = await vm.getAttMediaUriByHLS(_article.read_aloud_id)
            // console.log('  -----> 處理朗讀影片參數...')
          }
          // 處理解說參數
          if (_article.commentary_id) {
            _articles[_index].commentary_url = await vm.getAttMediaUriByHLS(_article.commentary_id)
            // console.log('  -----> 處理解說影片參數...')
          }
        }
      })

      // console.log('  -----> 處理文章資源網址完成....')
      return _articles
    },
    // 請求獲取朗讀或解說音視頻位址
    async getAttMediaUriByHLS (_attachmentID = '') {

      let path
      await this.$http.get('api/attachment/path/' + _attachmentID).then(async function (_response) {
        if (_response.body.code === '0000') {
          path = {
            ext: 'm3u8',
            url: this.$root.base_url + 'article/' + _response.body.data
          }
        }
      })
      // console.log('--> HLS 獲取結果: ', path)
      return path
    },
    // ####################################
    // Firebase
    // ####################################
    // 請求發送驗證簡訊
    sendPhoneNumberCode (_phoneNumber) {
      var vm = this
      console.log('--> 呼叫 FireBase Phone Auth...')
      var appVerifier = window.recaptchaVerifier
      firebase.auth().signInWithPhoneNumber(_phoneNumber, appVerifier)
        .then(function (confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult
          vm.goVer()
        }).catch(function (error) {
          console.log('--> ', error)
        })
    },
    // 請求驗證簡訊驗證碼
    confirmPhoneNumberCode (_phoneNumber, _code) {
      var vm = this
      // eslint-disable-next-line no-undef
      confirmationResult.confirm(_code).then(function (result) {
        // User signed in successfully.
        var user = result.user
        console.log('--> user: ', user)
        vm.bindMember(_phoneNumber)
      }).catch(function (error) {
        console.log('--> ', error)
        alert('驗證碼驗證失敗')
      })
    },
    //- 請求綁定會員
    async bindMember (_phoneNumber) {
      let phoneNumber = _phoneNumber.toString().substr(1)
      await this.$http.post('member/bind', {
        phone: phoneNumber,
        device_id: this.$root.getData('dev-id')
      }).then(async function (_response) {
        if (_response.body.code === '0000') {
          await this.getUser()
          await this.goHome()
        }
      }, function (_response) {
        if (_response.status === 500) {
          // this.$root.common.naviteAlert('發生問題', '請重新嘗試驗證')
          this.$root.getNewToken()
        }
      })
    },
    // ####################################
    // 系統操作類
    // ####################################
    //- 設定 Storage 資料
    setData (_key = '', _val = '') {
      Vue.localStorage.set(_key, _val)
      return true
    },
    //- 取得 Storage 資料
    getData (_key = '') {
      return Vue.localStorage.get(_key)
    },
    // 開啟 Loading 狀態
    useLoading () {
      this.isLoading = true
    },
    closeLoading () {
      this.isLoading = false
    },
    // 補0
    paddingLeft (str, lenght) {
      if (str.length >= lenght) { return str } else { return this.paddingLeft('0' + str, lenght) }
    },
    // ####################################
    // 畫面操作類
    // ####################################
    //- 至主畫面
    goHome () {
      this.$router.push({ name: 'home' })
      this.$root.nav_meta = 'home'
      this.hide_page_status = false
    },
    //- 至文章畫面
    async goArticle (_articleId = false, _canWatch, _onArticlePage = false) {
      if (!_canWatch) {
        this.useAlert(1, '付費文章', '目前這個文章尚未對您開放', '好')
        return
      }

      var vm = this
      this.useLoading()
      await this.$http.get('api/article/' + _articleId).then(async function (_response) {
        if (_response.body.code === '0000') {
          let article = _response.body.data
          let setArticles = await this.setArticlesImage([article])
          let getArticle = setArticles[0]

          // 驗證特殊類別
          let isSpecialModel = false
          article.categoriesObj.forEach(function (_item) {
            // 驗證是否有特殊類別
            console.log('--> 本次驗證父類別: ', _item.parent_id)
            if (vm.specialTyeps.indexOf(_item.parent_id) > -1) {
              isSpecialModel = true
            }
          })

          // 處理文章內容
          getArticle.article_paras.forEach(function (_para, _paraIndex) {
            // 初始化文章內容
            getArticle.article_paras[_paraIndex].content = {}
            getArticle.article_paras[_paraIndex].content_zh = {}
            getArticle.article_paras[_paraIndex].words = []
            // 找出相應的內容並塞入列表
            _para.paraContents.forEach(function (_content) {
              if (isSpecialModel) {
                if (_content.lang_type === 4) {
                  getArticle.article_paras[_paraIndex].content_zh = _content
                  getArticle.article_paras[_paraIndex].words = _content.words
                } else {
                  getArticle.article_paras[_paraIndex].content = _content
                  getArticle.article_paras[_paraIndex].words = _content.words
                }
              } else if (_content.lang_type === 4) {
                getArticle.article_paras[_paraIndex].content_zh = _content
              } else {
                getArticle.article_paras[_paraIndex].content = _content
                getArticle.article_paras[_paraIndex].words = _content.words
              }
            })
          })
          // 設置目前文章
          this.currentArticle = getArticle
          // 前往文章內頁
          this.article_id = _articleId
          // 前往文章
          if (!_onArticlePage) {
            this.$router.push({ name: 'article', params: { id: _articleId } })
          }
          // 關閉載入中
          setTimeout(() => { this.closeLoading() }, 150);
        }
      }, function (_resopnse) {
        // 關閉載入中
        this.useAlert(1, '無觀看權限', '很抱歉，您目前沒有這篇文章的觀看權限', '好')
        console.log('--> 前往文章時發生錯誤: ', _resopnse.body);
        setTimeout(() => { this.closeLoading() }, 250);
      })
    },
    //- 至註冊畫面
    goSignUp () {
      this.$router.push({ name: 'signup' })
    },
    //- 至收藏畫面
    goCollect () {
      this.$router.push({ name: 'collect' })
      this.nav_meta = ''
    },
    //- 至使用條款
    goRule (_tab) {
      this.$router.push({ name: 'rule' })
      this.rule_status = _tab
      this.nav_meta = ''
    },
    //- 至個人資料頁
    goProfile (_tab) {
      this.$router.push({ name: 'profile' })
      this.profile_status = _tab
      this.nav_meta = ''
    },
    //- 至一般登入畫面
    goPhoneLogin () {
      this.$router.push({ name: 'login-phone' })
    },
    //- 至掃描登入畫面
    goScanLogin () {
      this.$router.push({ name: 'login-scan' })
    },
    //- 至電話驗證畫面
    goVer () {
      this.$router.push({ name: 'verification' })
    },
    //- 關閉語言選擇bar
    closeSwitchLanBar () {
      this.$root.switch_bar_status = false
    },
    //- 關閉個人part bar
    closeHeadMenu () {
      this.$root.head_menu_status = false
    },
    //- 使用 alert
    useAlert (_type, _title = '', _text = '', _buttonTxt = '') {
      this.isAlert = true
      this.alert_type = _type
      this.alert = {
        title: _title,
        text: _text,
        button: _buttonTxt
      }
    },
    // 使用附檔名獲取檔案MediaType
    getAttachmentMediaType (_ext) {
      let mediaType = ''
      if (_ext === 'mp3') {
        mediaType = 'audio/mpeg'
      }
      if (_ext === 'mp4') {
        mediaType = 'video/mp4'
      }
      if (_ext === 'm3u8') {
        mediaType = 'application/x-mpegURL'
      }
      return mediaType
    },
    closeAlert () {
      this.isAlert = false
    }
  }
}).$mount('#app');
