<template lang="pug">
//- ProgramRecordMain
.container-fluid-xs
  //- 訂閱紀錄
  .program-record-all
    .title-content.text-align-left 訂閱項目
    //- 處理中
    .program-section
      .program-sm-title 處理中
      ProgramRecordItem(
        v-if="!plan.status",
        v-for="(plan, index) in plans",
        :plan="plan"
      )

    //- 訂閱中
    .program-section
      .program-sm-title 訂閱中
      ProgramRecordItem(
        v-if="plan.status && !plan.due_status",
        v-for="(plan, index) in plans",
        :plan="plan"
      )

    //- 已到期
    .program-section
      .program-section-left.float-left
        .program-sm-title 已到期
        .program-sm-text 查看您 地球村愛學習 所有訂閱已到期方案
      .program-section-right.float-right
        button.button.btn-op-default.btn-sm.btn-width-auto.more-button(
          @click="goDuePage()"
        )
          span 查看更多紀錄
      .clearfix

  //- 購買紀錄
  .buy-table-all
    .buy-table
      .table-title 購買紀錄
      table.table-normal
        thead
          tr
            th 訂單號碼
            th 日期
            th 方案項目
            th 到期日
            th 金額
        tbody(v-if="buy_list.length < 1")
          tr
            td(colspan="5") 目前沒有點數紀錄
        tbody(v-else)
          //- 僅顯示前10筆
          tr(v-if="!show_more", v-for="act in buy_list.slice(0, 10)")
            td {{ act.order_num }}
            td {{ act.date }}
            td {{ act.program }}
            td {{ act.end_date }}
            td NT$ {{ act.price }}
          //- 顯示全部
          tr(v-if="show_more", v-for="act in buy_list")
            td {{ act.order_num }}
            td {{ act.date }}
            td {{ act.program }}
            td {{ act.end_date }}
            td NT$ {{ act.price }}

      .buy-button-group(v-if="!show_more && buy_list.length > 10")
        button.button.btn-default.btn-sm.btn-width-auto.buy-button(
          @click="showMore()"
        )
          span 顯示前 3 個月紀錄
</template>
<script>
import ProgramRecordItem from '@/common/program-record-item'
export default {
  name: 'ProgramRecordMain',
  props: ['plans'],
  components: {
    ProgramRecordItem
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
      show_more: false,
      buy_list: [
        {
          order_num: 'HNDSJVI142',
          date: '2020-01-01',
          program: '日語2年費制',
          end_date: '2022-12-31',
          price: '2,000'
        },
        {
          order_num: 'HNDSJVI142',
          date: '2020-01-01',
          program: '日語2年費制',
          end_date: '2022-12-31',
          price: '2,000'
        },
        {
          order_num: 'HNDSJVI142',
          date: '2020-01-01',
          program: '日語2年費制',
          end_date: '2022-12-31',
          price: '2,000'
        },
      ]
    };
  },
  methods: {
    showMore () {
      this.show_more = true
    },
    goDuePage () {
      this.$emit('goDuePage')
    }
  }
}
</script>
