<template lang="pug">
//- 首頁
.history-view.page-index
  homeHeader
  .page-content
    .container-fluid-sm
      .title-content.text-align-left 觀看歷史
    .container-fluid-sm
      .have-not-history
        //- (v-if="is_null")
        img.nohistoryimg(src="@/assets/image/bookimg@3x.png")
        .nohistory-text 沒任何歷史紀錄
        .nohistory-text 趕快學習外語，讓語言進一步！
      //- .have-history
        //- 今天
        .today-list
          //- (v-if="articles.today.length > 0")
          .title-content.row.align-items-center.flex-nowrap
            .col-auto.text-align-left.flex-grow-1 今天
          .home-listcontent.row.align-items-stretch
            ArticleItem.home-list-item.cursor-pointer.col-lg-sp.col-md-3.col-sm-4.col-6(
              v-for="item in $root.articleList",
              :item="item"
            )
        //- 本週
        .week-list
          //- (v-if="articles.week.length > 0")
          .title-content.row.align-items-center.flex-nowrap
            .col-auto.text-align-left.flex-grow-1 本週
          .home-listcontent.row.align-items-stretch
            ArticleItem.home-list-item.cursor-pointer.col-lg-sp.col-md-3.col-sm-4.col-6(
              v-for="item in $root.articleList",
              :item="item"
            )
        //- 本月
        .month-list
          //- (v-if="articles.month.length > 0")
          .title-content.row.align-items-center.flex-nowrap
            .col-auto.text-align-left.flex-grow-1 本月
          .home-listcontent.row.align-items-stretch
            ArticleItem.home-list-item.cursor-pointer.col-lg-sp.col-md-3.col-sm-4.col-6(
              v-for="item in $root.articleList",
              :item="item"
            )
  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import ArticleItem from '@/common/article-item'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    ArticleItem,
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
