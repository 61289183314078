import Vue from 'vue'

var project
export default class Common {
    constructor(_vm) {
        project = _vm
    }

    // 產生 UUID
    createUUID () {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    // 處理電話號碼
    getPhoneCode (_countryCode = '+886', _regPhone = '') {
        if (_countryCode === '+886') {
            if (_regPhone.substr(0, 1) === '0') {
                return _countryCode + _regPhone.substr(1)
            } else {
                return _countryCode + _regPhone
            }
        } else {
            return _countryCode + _regPhone
        }
    }

    // 取得 preview URL
    async getMediaPreviewUri (_attachmentID, _onlyUrl = false) {
        if (!_attachmentID) {
            return ''
        }

        let url = project.base_url + 'app/attachment/' + _attachmentID + '/preview?DeviceToken=' + Vue.localStorage.get('api-token')

        if (_onlyUrl) {
            // console.log('---> 返回網址: ' + url)
            return url
        }

        let ext = await project.getAttachmentExt(_attachmentID)
        // console.log('---> 獲取副檔名: ', ext)

        if (!_onlyUrl) {
            // console.log('---> 返回物件: ', { ext: ext, url: url })
            return { ext: ext, url: url }
        }
    }

    // 格式化數字
    formatNumber (num = 0, _format = 2) {
        if (!num) {
            return 0.00
        } else {
            Number.prototype.numberFormat = function (c, d, t) {
                var n = this,
                    c = isNaN(c = Math.abs(c)) ? 2 : c,
                    d = d == undefined ? "." : d,
                    t = t == undefined ? "," : t,
                    s = n < 0 ? "-" : "",
                    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                    j = (j = i.length) > 3 ? j % 3 : 0;
                return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
            }

            var tempNumber = parseFloat(num)
            var number = tempNumber.numberFormat(_format, '.', ',')
            console.log('---> formatNumber: ' + num + ' to ' + number)
            return number
        }
    }
}