<template lang="pug">
//- 首頁
.program-view.page-index
  homeHeader
  .page-content
    .container-fluid-m
      .title-content.text-align-left 選擇最適合您的方案
      //- .program-tab.text-align-left.row
      //-   a.cursor-pointe.tab-btn.text-align-center.cant-select(
      //-     @click="changePeriod(false)",
      //-     v-bind:class="!program_period ? 'btn-active' : 'btn-noactive'"
      //-   ) 月費
      //-   a.cursor-pointe.tab-btn.text-align-center.cant-select(
      //-     @click="changePeriod(true)",
      //-     v-bind:class="program_period ? 'btn-active' : 'btn-noactive'"
      //-   ) 年費
      .program-box
        table.program-table
          thead
            .first-hr
            tr
              th.th-text.row1 
              th.th-text 生日費制
              th.th-text 時日費制
              th.th-text 生美費制
              th.th-text 時美費制
              th.th-text 日語VIP
              th.th-text 美語VIP
              th.th-text VVIP費制
          tbody
            //- 月費
            //- tr(v-if="!program_period")
              td.td-text.text-align-left.row1 方案期間/費用
              td.text-align-center(v-for="mon in mon_price")
                .td-pay-group
                  span.td-text {{ mon.price }}
                  .td-ps 每月自動繳款
                  .sub-button.cursor-pointer 訂閱
            //- 年費
            tr
              //- (v-if="program_period")
              td.td-text.text-align-left.row1 方案期間/費用
              td.text-align-center(v-for="year in year_price")
                .td-pay-group(v-for="item in year")
                  span.td-pay-text {{ item.price }}
                  .td-ps 年訂閱為一次付清
                  .sub-button.cursor-pointer(@click="$root.useAlert(2)") 訂閱
            //- 目項表
            tr.item-tr(v-for="(item, index) in item_list")
              td.td-text.text-align-left.row1
                .td-title-group
                  .td-title-1(
                    v-bind:class="[!item.title_2 ? 'hight-34rem' : '']"
                  ) {{ item.title_1 }}
                  .td-title-2 {{ item.title_2 }}
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_1",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_2",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_3",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_4",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_5",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_6",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
              td.text-align-center
                img.vv-img(
                  v-if="!item.vv_7",
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/cancel@3x.png"
                )
                img.vv-img(
                  v-else,
                  v-bind:class="[!item.title_2 ? 'vv-img-1title' : 'vv-img-2title']",
                  src="@/assets/image/vv.png"
                )
            //- 月費
            //- tr(v-if="!program_period")
              td.td-text.text-align-left.row1 方案期間/費用
              td.text-align-center(v-for="mon in mon_price")
                .td-pay-group
                  span.td-text {{ mon.price }}
                  .td-ps 每月自動繳款
                  .sub-button.cursor-pointer 訂閱
            //- 年費
            //- tr.last-col
              //- (v-if="program_period")
              td.td-text.text-align-left.row1 方案期間/費用
              td.text-align-center(v-for="year in year_price")
                .td-pay-group(v-for="item in year")
                  span.td-text {{ item.price }}
                  .td-ps 每月自動繳款
                  .sub-button.cursor-pointer 訂閱

  Footer
</template>
<script>
// import $ from 'jquery'
import homeHeader from '@/components/home-header'
import Footer from '@/components/home-footer'
import ArticleItem from '@/common/article-item'
export default {
  name: 'Index',
  components: {
    homeHeader,
    Footer,
    ArticleItem,
  },
  data () {
    return {
      program_period: false,
      mon_price: [
        {
          price: 'NT$190 /月'
        },
        {
          price: 'NT$280 /月'
        },
        {
          price: 'NT$370 /月'
        },
        {
          price: 'NT$460 /月'
        }
      ],
      year_price: [
        [
          {
            price: 'NT$2,190 /年'
          },
          {
            price: 'NT$4,190 /2年'
          },
          {
            price: 'NT$5,990 /3年'
          }
        ],
        [
          {
            price: 'NT$2,190 /年'
          },
          {
            price: 'NT$4,190 /2年'
          },
          {
            price: 'NT$5,990 /3年'
          }
        ],
        [
          {
            price: 'NT$2,190 /年'
          },
          {
            price: 'NT$4,190 /2年'
          },
          {
            price: 'NT$5,990 /3年'
          }
        ],
        [
          {
            price: 'NT$2,190 /年'
          },
          {
            price: 'NT$4,190 /2年'
          },
          {
            price: 'NT$5,990 /3年'
          }
        ],
        [
          {
            price: 'NT$3,190 /年'
          },
          {
            price: 'NT$5,990 /2年'
          },
          {
            price: 'NT$8,390 /3年'
          }
        ],
        [
          {
            price: 'NT$3,190 /年'
          },
          {
            price: 'NT$5,990 /2年'
          },
          {
            price: 'NT$8,390 /3年'
          }
        ],
        [
          {
            price: 'NT$5,390 /年'
          },
          {
            price: 'NT$9,990 /2年'
          },
          {
            price: 'NT$14,900 /3年'
          }
        ]
      ],
      item_list: [
        {
          title_1: 'Demo',
          title_2: '',
          vv_1: true,
          vv_2: true,
          vv_3: true,
          vv_4: true,
          vv_5: true,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '生活美語',
          title_2: '當期數位學習',
          vv_1: false,
          vv_2: false,
          vv_3: true,
          vv_4: false,
          vv_5: false,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '時尚美語',
          title_2: '當期數位學習',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: true,
          vv_5: false,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '生活美語',
          title_2: '經典數位學習',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '時尚美語',
          title_2: '經典數位學習',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '生活日語',
          title_2: '當期數位學習',
          vv_1: true,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: true,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '時尚日語',
          title_2: '當期數位學習',
          vv_1: false,
          vv_2: true,
          vv_3: false,
          vv_4: false,
          vv_5: true,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '生活日語',
          title_2: '經典數位學習',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: true,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '時尚日語',
          title_2: '經典數位學習',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: true,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '短篇快訊美語',
          title_2: '',
          vv_1: true,
          vv_2: true,
          vv_3: true,
          vv_4: true,
          vv_5: true,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '經典快訊美語',
          title_2: '',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: true,
          vv_7: true,
        },
        // {
        //   title_1: '超優快訊美語',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        {
          title_1: '美日韓語',
          title_2: '叢書影片',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '美日韓語',
          title_2: '課程影片',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: false,
          vv_7: true,
        },
        // {
        //   title_1: '美語超優朗讀',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '日語超優朗讀',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '韓語超優朗讀',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '美語超優解說',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '日語超優解說',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '韓語超優解說',
        //   title_2: '',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        {
          title_1: '美語檢定聽力',
          title_2: '',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '日語檢定聽力',
          title_2: '',
          vv_1: false,
          vv_2: false,
          vv_3: false,
          vv_4: false,
          vv_5: false,
          vv_6: false,
          vv_7: true,
        },
        // {
        //   title_1: '兒少美語',
        //   title_2: '當季兒少月刊',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '經典兒少月刊',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '直覺發音',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '字彙學習',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '課本1~4',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '課本5~8',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '課本9~12',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '繪本1~3',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少美語',
        //   title_2: '繪本4~5',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '當季兒少月刊',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '經典兒少月刊',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '日語50音',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '字彙練習',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '課本1~4',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '課本5~8',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        // {
        //   title_1: '兒少日語',
        //   title_2: '課本9~12',
        //   vv_1: false,
        //   vv_2: false,
        //   vv_3: false,
        //   vv_4: false,
        //   vv_5: false,
        //   vv_6: false,
        //   vv_7: false,
        // },
        {
          title_1: '美語旅學台灣',
          title_2: '',
          vv_1: false,
          vv_2: false,
          vv_3: true,
          vv_4: true,
          vv_5: false,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '日語旅學台灣',
          title_2: '',
          vv_1: true,
          vv_2: true,
          vv_3: false,
          vv_4: false,
          vv_5: true,
          vv_6: false,
          vv_7: true,
        },
        {
          title_1: '韓語旅學台灣',
          title_2: '',
          vv_1: true,
          vv_2: true,
          vv_3: true,
          vv_4: true,
          vv_5: true,
          vv_6: true,
          vv_7: true,
        },
        {
          title_1: '美日韓語',
          title_2: '有聲教材',
          vv_1: true,
          vv_2: true,
          vv_3: true,
          vv_4: true,
          vv_5: true,
          vv_6: true,
          vv_7: true,
        }
      ]
    };
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    changePeriod (_status) {
      this.program_period = _status
    }
  }
}
</script>
<style lang="sass">
.hight-34rem
  line-height: 3rem
.vv-img-1title
  height: 1.4rem
  position: relative
  top: .4rem
.vv-img-2title
  height: 1.4rem
  position: relative
  top: 1.3rem
</style>
