<template lang="pug">
.sub-alert-view
  .alert-title 訂閱方案
  .alert-text
    span 日語月費制
    span.sub-text-ml.font-weight-bold NT$190
  .alert-text
    span 方案期間1個月
    span.sub-text-ml 每月自動扣繳
  .sub-item-group
    .sub-item.align-items-center(v-for="item in item_list")
      img.sub-vv.float-left(src="@/assets/image/path@3x.png")
      .alert-text.float-left {{ item.title }}
      .clearfix
  .alert-text
    span 已表示同意地球村美日韓語
    span.sub-green.cursor-pointer(@click="alertGoRule()") 使用者購買消費合約協議
  .alert-button-group
    button.button.btn-default.white-btn.btn-width-auto.alert-btn(
      @click="$root.closeAlert()"
    )
      span 我考慮
    button.button.btn-default.purple-btn.btn-width-auto.alert-btn
      span 確定訂閱
  .clearfix
</template>
<script>
// import $ from 'jquery'
export default {
  name: '',
  props: [],
  components: {},
  data () {
    return {
      item_list: [
        {
          title: '生活美語當期互動學習',
        },
        {
          title: '時尚美語當期互動學習',
        },
        {
          title: '生活日語當期互動學習',
        },
        {
          title: '高級日語當期互動學習',
        },
        {
          title: '新聞美語最新互動學習',
        },
        {
          title: '生活美語最新互動學習',
        },
        {
          title: '時尚美語經典互動學習',
        },
        {
          title: '生活日語經典互動學習',
        },
        {
          title: '生活日語經典互動學習',
        },
        {
          title: '經典互動學習',
        },
        {
          title: '美語檢定聽力',
        },
        {
          title: '日語檢定聽力',
        },
        {
          title: '兒少美語互動學習',
        },
        {
          title: '兒少日語互動學習',
        },
        {
          title: '美日韓語叢書影片',
        },
        {
          title: '美日韓語課程影片',
        },
      ]
    };
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    alertGoRule () {
      this.$root.goRule(3)
      this.$root.closeAlert()
    }
  }
}
</script>
