<template lang="pug">
//- connet-group
.connet-group
  //- large-時出現
  .connet-box.d-lg-block.d-none
    .row.align-items-right.flex-nowrap
      .offset-3
      .connet-item.col.flex-shrink-0.cursor-pointer.first.text-align-center
        span 隱私政策
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 使用條款
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 法律信息
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 幫助中心
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 關於我們
    .row.align-items-right.flex-nowrap
      .offset-3
      .connet-item.col.flex-shrink-0.first.text-align-right
        span Email: 123456789@mail.com
      .connet-item.col.flex-shrink-0.text-align-left.font-weight-bold
        span © 2020 edu1n1 AlI Rights Reservrd.

  //- large以外時出現
  .connet-box.d-lg-none
    .row.align-items-right.flex-nowrap
      .connet-item.col.flex-shrink-0.cursor-pointer.first.text-align-center
        span 隱私政策
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 使用條款
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 法律信息
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 幫助中心
      .connet-item.col.flex-shrink-0.cursor-pointer.text-align-center
        span 關於我們
    .row.align-items-right.flex-nowrap
      .connet-item.col.flex-shrink-0.first.text-align-right
        span Email: 123456789@mail.com
      .connet-item.col.flex-shrink-0.text-align-left.font-weight-bold
        span © 2020 edu1n1 AlI Rights Reservrd.
</template>
<script>
export default {
  props: ['item'],
  name: 'connetgroup',
  components: {

  },
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
