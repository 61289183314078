<template lang="pug">
//- supplement
.supplement
  .supplement-title(v-if="!specialMode") 重點補充
  .supplement-title(v-else) 解答
  .supplement-item(v-for="(list, index) in sp_list")
    //- 一般文章
    .normal-article(v-if="!specialMode")
      //- 中英文
      .sp-item-title
        span {{ index + 1 }}.
        span {{ list.words_foreign }}
        span {{ list.words_zh }}
      //- kk 英標
      .sp-item-kk
        span(v-if="list.kk") {{ list.kk }}
      //- 詞性
      .sp-item-part
        span.form-type(v-if="list.form") {{ list.form }}
        //- span.form-text {{ list.words_zh }}
      //- 星星收藏
      img.star-img.cursor-pointer(
        v-if="list.be_kept",
        src="@/assets/image/star@3x.png"
      )
      img.star-img.cursor-pointer(
        v-else,
        src="@/assets/image/star-empty@3x.png"
      )
      //- 音訊播放
      //- .have_media
        img.sound-img.cursor-pointer(src="@/assets/image/sound-a@3x.png")
        //- img.sound-img.cursor-pointer(src="@/assets/image/sound@3x.png")
        //- .media-body
          video-player.video-player-box(
            v-if="currentMedia",
            :options="playerOptions",
            customEventName="customstatechangedeventname",
            :webkit-playsinline="true",
            :playsinline="true",
            :x5-playsinline="true",
            @play="onPlayerPlay",
            @pause="onPlayerPause",
            @ended="onPlayerEnded",
            @ready="playerReadied"
          )
    //- 問題文章
    .specil-article(v-else)
      .item-img-box(v-if="list.image")
        img.item-img(:src="list.image")
      .content-psitem
        .item-currect(v-html="list.words_zh.replace(/\\r?\\n/g, '<br>')")
</template>
<script>
export default {
  props: ['sp_list', 'specialMode'],
  name: 'supplement',
  components: {

  },
  data () {
    return {

    };
  },
  created () {

  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
